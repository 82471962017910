<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showHelp"
    width="500"
    persistent
    @keydown.esc="localClose"
  >
    <details-card scrollable>
      <v-card-title>
        <prosk-title>
          {{ $t("needSomeHelp") }}
        </prosk-title>
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <custom-text-field
            :label="$t('needSomeHelpWithThisJob') + ' ?'"
            required
            :error-count="helpError.length"
            :error-messages="helpError"
            :hide-details="helpError.length === 0"
            v-model="help"
          ></custom-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button :action="localClose" outlined :message="$t('cancel')"/>
        <general-button
          :action="isProsker ? proskerAskHelpJob : clientAskHelpJob"
          :loading="loading"
          :message="$t('continue')"
        />
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';
import CustomTextField from '@/components/socialvue/customTextField/CustomTextField.vue';

export default {
  name: 'HelpDialog',
  components: { GeneralButton, DetailsCard, ProskTitle, CustomTextField },
  props: ['idJob', 'onClose', 'showHelp', 'isProsker', 'onAdded'],
  mixins: [errorDialog],
  data () {
    return {
      help: null,
      loading: false,
      helpError: []
    };
  },
  methods: {
    localClose () {
      this.onClose();
      this.help = null;
    },
    clientAskHelpJob () {
      this.helpError = [];
      if (this.help) {
        this.loading = true;
        const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${this.idJob}`;
        const data = {
          status: 110,
          reason_help: this.help
        };
        axios
            .patch(url, data)
            .then(() => {
              this.onClose();
              this.onAdded();
              this.loading = false;
              this.help = null;
            })
            .catch((error) => {
              this.loading = false;
              this.help = null;
              this.showError(error);
            });
      } else {
        this.helpError.push(
            this.$t('fieldHelpRequired')
        );
      }
    },
    proskerAskHelpJob () {
      this.helpError = [];
      if (this.help) {
        this.loading = true;
        const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${this.idJob}`;
        const data = {
          status: 100,
          reason_help: this.help
        };
        axios
            .patch(url, data)
            .then(() => {
              this.onClose();
              this.onAdded();
              this.loading = false;
              this.help = null;
            })
            .catch((error) => {
              this.loading = false;
              this.help = null;
              this.showError(error);
            });
      } else {
        this.helpError.push(
            this.$t('fieldHelpRequired')
        );
      }
    }
  }
};
</script>
