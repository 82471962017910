<template>
  <v-rating
    class="rating-component"
    :value="value"
    @input="$emit('input', $event.target.value)"
    :readonly="readonly"
    background-color="#6B71F24D"
    color="title_highlight_color"
    empty-icon="mdi-star"
    size="19"
  />
</template>
<script>
export default {
  name: 'Rating',
  props: ['value', 'readonly']
};
</script>
<style scoped>
.rating-component >>> .v-icon {
  padding: 0;
}
</style>
