const mixin = {
    data () {
        return {
            maxFileSize: process.env.VUE_APP_MAX_FILE_SIZE,
            canSaveImage: false
        };
    },
    methods: {
        fileInputClick (ref) {
            this.$refs[ref].$refs.input.click();
        },
        handleFileUpload (file) {
            if (!file) return;

            if (file.size > this.maxFileSize) {
                this.canSaveImage = false;
                this.$swal.fire({ text: this.$t('imageSizeWarning') });
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                this.canSaveImage = true;
            };
            reader.readAsArrayBuffer(file);
        }
    }
};

export default mixin;
