<template>
<v-main class="container-prosker">
  <v-row class="main-row" :class="($vuetify.breakpoint.smAndDown) ? 'sm' : ''">
    <delete-warning
      :confirm-delete="deletePost"
      :on-close="onDeleteClose"
      :show-delete="showDelete"
      :loading="loadingPosts"
    />
    <delete-warning
      :confirm-delete="deleteUserCategory"
      :on-close="onDeleteUserCategoryClose"
      :show-delete="showUserCategoryDelete"
      :loading="loadingUserCategory"
    />
    <delete-warning
      :confirm-delete="deleteUserSkill"
      :on-close="onDeleteUserSkillClose"
      :show-delete="showUserSkillDelete"
      :loading="loadingUserSkill"
    />
    <delete-warning
      :confirm-delete="deleteComment"
      :on-close="onDeleteCommentClose"
      :show-delete="showCommentDelete"
      :loading="loadingUserSkill"
    />
    <v-col cols="12" md="5">
      <details-card>
        <template v-slot:title>
          {{ $t("contactInformation") }}
        </template>
        <template v-slot:content>
          <div class="info-row">
            <h6 class="main_text_color--text">
              <prosk-icon icon="user" />
              {{ $t("name") }}:
            </h6>
            <p class="main_text_color--text">{{ currentUser.first_name }} {{ currentUser.last_name }}</p>
          </div>
          <div class="info-row">
            <h6 class="main_text_color--text" v-show="visitorUser && visitorUser.type_user === $options.USER_TYPE_ADMIN">
              <prosk-icon class="title_highlight_color--text" icon="mail" />
              {{ $t("email") }}:
            </h6>
            <p class="main_text_color--text" v-show="visitorUser && visitorUser.type_user === $options.USER_TYPE_ADMIN">{{ currentUser.email }}</p>
          </div>
          <v-divider class="divider" />

          <h4 class="title inner">{{ $t("profile") }}</h4>
          <p v-if="currentUser.description" class="normal-text main_text_color--text">{{ currentUser.description }}</p>
          <alert v-show="(currentUser && currentUser.type_user) >= $options.USER_TYPE_PROSKER" :message="!isSearchedUser ? $t('profileEmpty') : $t('userProfileEmpty')" v-else/>

          <div class="profile-buttons">
            <user-dialog
              :item="currentUser"
              v-if="!isSearchedUser"
              :on-close="onAddClose"
              :show-add="showAdd"
              :is-edit="isEdit"
              :new-item="showUserDialog"
            >
              <template v-slot:customButton="{ on, click }" >
                <general-button
                  :action="()=>{click(); sendDataEditProfileClickLayerInfo()}"
                  secondary
                  small
                  v-on="on"
                  id="edit-profile-btn"
                >
                  <prosk-icon icon="edit" />
                  {{ $t(isEdit ? 'editUser' : 'createUser') }}
                </general-button>
              </template>
            </user-dialog>
            <general-button
              :to="{ name: 'social.my-addresses' }"
              small
              outlined
              v-if="!isSearchedUser && currentUser.type_user >= $options.USER_TYPE_PROSKER"
            >
              <prosk-icon icon="direcciones" />
              {{ $t("myAddresses") }}
            </general-button>
          </div>

          <div v-if="!isSearchedUser && !currentUser.mp_access_token && (currentUser && currentUser.type_user) >= $options.USER_TYPE_PROSKER">
            <v-divider></v-divider>
            <div class="mt-3">
              <general-button
                :action="createMPAccount"
                small
                class="mp-button"
              >
                <img width="20" class="mr-3 round" src="@/assets/images/page-img/mp.png" alt="mercado pago" />
                {{ $t("associateAccount") }}
              </general-button>
            </div>
          </div>
        </template>
      </details-card>

      <details-card>
        <template v-slot:title>
          {{ $t("categories") }}
          <add-category-to-user
            :user="currentUser"
            v-if="!isSearchedUser"
            :on-close="onAddCloseCategory"
            :show-add="showAddCategory"
            :new-item="addCategoryToUser"
          />
        </template>
        <template v-slot:content>
          <v-row v-if="currentUser.categories.length !== 0">
            <v-chip-group column>
              <v-chip
                v-for="(item, i) in currentUser.categories"
                :key="i"
                class="ma-2 main_text_color--text primary_light"
                :close="!isSearchedUser"
                :disabled="loadingUserCategory"
                @click:close="openUserCategoryWarning(item.id)"
              >
                {{ item.name }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row v-else>
            <alert :message="!isSearchedUser ? $t('noCategories') : $t('userNoCategories')"/>
          </v-row>
        </template>
      </details-card>

      <details-card>
        <template v-slot:title>
          {{ $t("skills") }}
          <add-skills-to-user
            :user="currentUser"
            v-if="!isSearchedUser"
            :on-close="onAddCloseSkill"
            :show-add="showAddSkill"
            :new-item="addSkillToUser"
          />
        </template>
        <template v-slot:content>
          <v-row v-if="currentUser.skills.length !== 0">
            <v-chip-group column>
              <v-chip
                v-for="(item, i) in currentUser.skills"
                :key="i"
                class="title_highlight_color"
                close-icon="mdi-close"
                outlined
                :close="!isSearchedUser"
                :disabled="loadingUserSkill"
                @click:close="openUserSkillsWarning(item.id)"
              >
                {{ item.skill_name }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row v-else>
            <alert :message="!isSearchedUser ? $t('noSkills') : $t('userNoSkills')"/>
          </v-row>
        </template>
      </details-card>

      <details-card v-if="(currentUser && currentUser.type_user) >= $options.USER_TYPE_PROSKER">
        <template v-slot:title>
          {{ $t("userReviews") }}
        </template>
        <template v-slot:content>
          <UserReviewsCore :current-user="currentUser" />
        </template>
      </details-card>
    </v-col>

    <v-col>
      <profile-completion v-if="isCurrentUser" />

      <details-card  v-if="isCurrentUser">
        <template v-slot:title>
          {{ $t("createPost") }}
        </template>
        <template v-slot:content>
          <v-row>
            <v-col cols="3" md="2" class="d-none d-md-block">
              <img
                v-if="!currentUser.profile_img"
                src="@/assets/images/page-img/avatar.png"
                class="rounded-circle avatar-70"
                width="70"
                alt="user"
              />
              <img
                v-else
                :src="currentUser.profile_img"
                class="rounded-circle avatar-70"
                alt="user"
                width="70"
                height="70"
              />
            </v-col>
            <v-col cols="12" md="10">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="title"
                    outlined
                    dense
                    :placeholder="$t('titlePost')"
                    color="gray_stroke"
                    class="input-text"
                  ></v-text-field>
                  <v-text-field
                    v-model="content"
                    :placeholder="$t('contentPost')"
                    outlined
                    dense
                    :hide-details="contentErrors.length === 0"
                    :error-count="contentErrors.length"
                    :error-messages="contentErrors"
                    color="gray_stroke"
                    class="input-text"
                  ></v-text-field>
                  <div class="d-flex flex-column mt-4">
                    <v-file-input
                      v-if="!url_video"
                      class="custom-file-field secondary-prosk"
                      dense
                      @change="handleFileUpload"
                      accept="image/*"
                      v-model="url_image"
                      :placeholder="$t('noSelectedFiles')"
                      prepend-icon=""
                      ref="imageInput"
                    >
                      <template v-slot:prepend-inner>
                        <button class="file-button main_text_color--text" @click="fileInputClick('imageInput')">
                          <v-icon size="16px">mdi-plus-circle-outline</v-icon>
                          <span>{{ $t('clickToAddImage') }}</span>
                        </button>
                      </template>
                    </v-file-input>

                    <v-file-input
                      v-if="!url_image"
                      :rules="videoRules"
                      class="custom-file-field secondary-prosk"
                      dense
                      @change="handleFileUpload"
                      accept="video/*"
                      v-model="url_video"
                      :placeholder="$t('noSelectedFiles')"
                      prepend-icon=""
                      ref="videoInput"
                    >
                      <template v-slot:prepend-inner>
                        <button class="file-button main_text_color--text" @click="fileInputClick('videoInput')">
                          <v-icon size="16px">mdi-plus-circle-outline</v-icon>
                          <span>{{ $t('clickToAddVideo') }}</span>
                        </button>
                      </template>
                    </v-file-input>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <general-button
                    secondary
                    responsive
                    :action="createPost"
                    :loading="loading"
                  >
                    {{ $t("post") }}
                  </general-button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </details-card>

      <v-card elevation="0" class="round" v-if="!loadingPostsList && posts.length === 0 && currentUser.type_user >= $options.USER_TYPE_PROSKER">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <alert :message="$t('noPosts')"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div v-if="posts.length && currentUser.type_user >= $options.USER_TYPE_PROSKER">
        <prosk-title>
          {{ $t("posts") }}
        </prosk-title>

        <v-card elevation="0"
          :class="(isSearchedUser && index > 0) || !isSearchedUser ? 'mt-5' : ''"
          v-for="(item, index) in posts" :key="index"
          class="round"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex justify-content-between">
                <div>
                  <div class="d-flex align-items-center">
                    <img
                      v-if="!currentUser.profile_img"
                      src="@/assets/images/page-img/avatar.png"
                      class="avatar-60 me-3 rounded-circle img-fluid"
                      width="70"
                      alt="user"
                    />
                    <img
                      v-else
                      :src="currentUser.profile_img"
                      class="avatar-60 me-3 rounded-circle img-fluid"
                      alt="user"
                      width="70"
                      height="70"
                    />
                    <div class="text-left">
                      <p class="ma-0 font-weight-bold secondary-prosk">{{ currentUser.username }}</p>
                      <span class="secondary-prosk">{{ getPostHour(item.created_at) }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="!isSearchedUser">
                  <v-menu transition="slide-y-transition" left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" class="custom-btn">
                        <v-icon class="secondary-prosk">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item dense ripple @click="openWarning(item.id)">
                        <v-btn icon x-small class="float-left custom-btn">
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                        {{ $t("delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-6 text-left">
              <v-col cols="12" class="pb-0">
                <h5 class="mb-2 secondary-prosk" v-if="item.title">{{ item.title }}</h5>
              </v-col>
            </v-row>
            <v-row class="px-6 text-left">
              <v-col cols="12" class="py-0">
                <p class="mt-0 mb-2 secondary-prosk" v-if="item.content">{{ item.content }}</p>
              </v-col>
            </v-row>
            <v-row class="px-6">
              <v-col cols="12">
                <img
                  v-if="item.url_image"
                  :src="item.url_image"
                  alt="post-image"
                  class="img-fluid w-100 round"
                />
              </v-col>
            </v-row>
            <v-row class="px-6">
              <v-col cols="12">
                <video
                  class="w-100"
                  id="video-preview"
                  controls
                  v-if="item.url_video"
                >
                  <source :src="item.url_video" />
                </video>
              </v-col>
            </v-row>
            <v-row class="margin-y" align="center">
              <v-col cols="3">
                <v-btn v-if="isSearchedUser" class="custom-btn" icon @click="toggleLike(item, item.has_like ? 0 : 1)">
                  <v-icon class="mr-1 secondary-prosk">
                    {{ item.has_like ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                  </v-icon>
                </v-btn>
                <span v-if="item.count_likes > 0" class="secondary-prosk">{{ item.count_likes }} {{ $t('like') }}</span>
                <span v-if="item.count_likes === 0 && isSearchedUser" class="secondary-prosk">{{ $t('like') }}</span>
              </v-col>
              <v-col class="secondary-prosk cursor-pointer">
                <span v-show="isSearchedUser && visitorUser" >
                  {{ `${(item.comments && item.comments.length) || 0} ${$t('comments')}` }}
                </span>
                <button
                  @click="toggleShowComments(item)"
                  class="ml-5 secondary-prosk cursor-pointer font-weight-bold"
                  v-show="item.comments && item.comments.length > 2"
                >
                  {{ $t('showAll') }}
                </button>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="input-comment" v-show="isSearchedUser">
            <input
              :placeholder="$t('commentsPlaceholder')"
              v-model="item.comment"
              @keyup.enter="addComment(item)()"
            />
            <general-button
              class="send-comment-btn"
              secondary
              :action="addComment(item)"
            >
              <v-icon>mdi-send</v-icon>
            </general-button>
            <span class="invalid-tooltip">{{ forceRender }}</span>
          </v-card-text>

          <div class="comments-wrapper">
            <div class="comment" v-for="(comment, index) in item.comments" :key="index" v-show="(index >= item.comments.length - 2) || item.showComments">
              <div class="ml-10" v-if="item.comments && item.comments.length">
                <div class="input-enter">
                  <p class="comment-name"> {{ item.comments && item.comments[item.comments.length - index - 1].user.first_name }} {{ item.comments && item.comments[item.comments.length - index - 1].user.last_name }} </p>
                  <span
                    class="comment-text"
                    v-show="!(commentEditing.post === item.id && commentEditing.comment === item.comments[item.comments.length - index - 1].id)"
                  >{{ item.comments && item.comments[item.comments.length - index - 1].comment }}</span>
                  <input
                    v-show="commentEditing.post === item.id && commentEditing.comment === item.comments[item.comments.length - index - 1].id"
                    class="input-comment"
                    v-model="item.comments[0].comment"
                    @keyup.enter="updateComment(item, item.comments[item.comments.length - index - 1].id, 0)"
                  />
                </div>
              </div>
              <div v-if="item.comments && item.comments.length && visitorUser && visitorUser.id === item.comments[item.comments.length - index - 1].user.id">
                <v-menu transition="slide-y-transition" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="custom-btn">
                      <v-icon class="secondary-prosk">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item dense ripple @click="onCommentEdit(item.id, item.comments[item.comments.length - index - 1].id)">
                      <v-btn icon x-small class="float-left custom-btn">
                        <v-icon class="mr-2"> mdi-pencil </v-icon>
                      </v-btn>
                      {{ $t("edit") }}
                    </v-list-item>
                    <v-list-item dense ripple @click="openCommentWarning(item.id, item.comments[item.comments.length - index - 1].id)">
                      <v-btn icon x-small class="float-left custom-btn">
                        <v-icon class="mr-2"> mdi-delete </v-icon>
                      </v-btn>
                      {{ $t("delete") }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>

        </v-card>
        <v-main class="text-center mt-5" v-if="!hideMorePostsBtn">
          <v-btn
              x-small
              plain
              link
              color="teal darken-4"
              :disabled="loadingPostsList"
              @click="getPosts(true)"
              class="custom-btn"
            >
              <v-icon class="secondary-prosk">mdi-cached</v-icon>
              <span class="ml-2 secondary-prosk">{{ $t("loadOldPosts") }}</span>
          </v-btn>
        </v-main>
      </div>
      <v-card outlined elevation="3" :class="!isSearchedUser ? 'mt-5' : ''"  v-if="loadingPostsList" class="round">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
              v-for="index in 3"
              :key="index"
              v-bind="skeletonAttrs"
              type="list-item-avatar, divider, list-item-three-line, card-heading, actions">
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-main>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapActions } from 'vuex';
import UserDialog from '@/components/socialvue/dialogs/UserDialog';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';
import AddCategoryToUser from '@/components/socialvue/dialogs/AddCategoryToUser';
import AddSkillsToUser from '@/components/socialvue/dialogs/AddSkillsToUser';
import UserReviewsCore from '@/views/User/UserReviewsCore';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { USER_TYPE_PROSKER, USER_TYPE_ADMIN } from '@/misc/constants';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import verifyImageSize from '@/components/socialvue/mixins/verifyImageSize';
import ProfileCompletion from '@/views/User/ProfileCompletion';
import userDataMixin from '@/mixins/userDataMixin';
import ProskIcon from '@/icons/ProskIcon.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';

export default {
  name: 'UserDetails',
  props: ['currentUser', 'isSearchedUser', 'visitorUser'],
  components: {
    Alert,
    AddSkillsToUser,
    AddCategoryToUser,
    UserDialog,
    DeleteWarning,
    DetailsCard,
    UserReviewsCore,
    GeneralButton,
    ProskIcon,
    ProskTitle,
    ProfileCompletion
  },
  mixins: [errorDialog, verifyImageSize, userDataMixin],
  data () {
    return {
      contentErrors: [],
      isEdit: true,
      loadingUserCategory: false,
      loadingUserSkill: false,
      loadingPosts: false,
      loadingComments: false,
      title: '',
      url_image: null,
      content: null,
      url_video: null,
      url: null,
      videoRules: [
        v => !v || this.verifyVideoSize(v)
      ],
      loading: false,
      showDialogUserBanner: false,
      showAdd: false,
      showAddCategory: false,
      showBanDialog: false,
      showAddSkill: false,
      dialogBannerPhoto: false,
      dialogUserPhoto: false,
      userIds: [],
      userData: {},
      name: '',
      posts: [],
      hideMorePostsBtn: false,
      page: 1,
      showDelete: false,
      showAddImage: false,
      postIdToDelete: '',
      commentIdToDelete: '',
      idCategory: [],
      idSkill: [],
      forceRender: false,
      commentEditing: {
        post: 0,
        comment: 0
      },
      showUserCategoryDelete: false,
      showUserSkillDelete: false,
      showCommentDelete: false,
      skeletonAttrs: {
        class: 'mb-6',
        elevation: 0
      },
      loadingPostsList: true
    };
  },
  created () {
    this.setImportedConstants();
    this.getPosts();
  },
  computed: {
    userLoggedIn () {
      return this.$store.getters['auth/currentUser'];
    },
    isCurrentUser () {
      return this.userLoggedIn && this.currentUser && this.userLoggedIn.id === this.currentUser.id;
    }
  },
  methods: {
    toggleLike (item, addLike) {
      if (!this.userLoggedIn) {
        this.$swal.fire({ title: this.$t('accessContentNeedLogIn') });
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/likes-post/${item.id}`;
        const data = {
          like: addLike
        };
        axios.post(url, data).then(() => {
          item.has_like = addLike;
          addLike ? item.count_likes++ : item.count_likes--;
        }).catch((error) => {
          this.showError(error);
        });
      }
    },
    toggleShowComments (item) {
      item.showComments = !item.showComments;
      this.forceRender = !this.forceRender;
    },
    addComment (item) {
      return () => {
        console.log('clicked');
        if (!this.userLoggedIn) {
          this.$swal.fire({ title: this.$t('accessContentNeedLogIn') });
          this.$router.push({ name: 'auth1.sign-in1' });
          return;
        }

        const url = `${process.env.VUE_APP_BASE_URL}/api/post/${item.id}/comments`;
        const data = {
          comment: item.comment
        };

        axios.post(url, data).then((response) => {
          item.comments = item.comments || [];
          item.comments.push(response.data.data[0]);
          item.comment = '';
          this.forceRender = !this.forceRender;
        }).catch((error) => {
          this.showError(error);
        });
      }
    },
    onCommentEdit (item, comment) {
      this.commentEditing = {
        post: item,
        comment: comment
      }
    },
    updateComment (item, apiIndex, index) {
      return () => {
        console.log('here is doing, apiIndex', apiIndex);
        const url = `${process.env.VUE_APP_BASE_URL}/api/post/${item.id}/comments/${apiIndex}`;
        const data = {
          comment: item.comments[index].comment
        };

        axios.put(url, data).then(() => {
          this.commentEditing = {
            post: 0,
            comment: 0
          }
        }).catch((error) => {
          this.showError(error);
        });
      }
    },
    deleteComment () {
      this.loadingComments = true;

      const url = `${process.env.VUE_APP_BASE_URL}/api/comments/${this.commentIdToDelete}`;
      axios.delete(url).then(() => {
        this.posts = this.posts.map((post) => {
          post.comments = post.comments.filter((comment) => {
            return comment.id !== this.commentIdToDelete;
          });
          return post;
        });
      }).catch((error) => {
        this.showError(error);
      }).finally(() => {
        this.loadingComments = false;
        this.onDeleteCommentClose();
      })
    },
    setImportedConstants () {
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.USER_TYPE_ADMIN = USER_TYPE_ADMIN;
    },
    createMPAccount () {
      window.location.href = `${process.env.VUE_APP_MP_AUTH_URL}`;
    },
    getUserAddress (currentUser) {
      let defaultAddress = '';
      currentUser.addresses.forEach((address) => {
        if (!address.is_default) {
          defaultAddress = address.city + ',' + address.country;
        }
      });
      return defaultAddress;
    },
    onDeleteClose () {
      this.showDelete = false;
    },
    onDeleteUserCategoryClose () {
      this.idCategory = [];
      this.showUserCategoryDelete = false;
    },
    onDeleteUserSkillClose () {
      this.idSkill = [];
      this.showUserSkillDelete = false;
    },
    onDeleteCommentClose () {
      this.showCommentDelete = false;
    },
    openWarning (item) {
      this.showDelete = true;
      this.postIdToDelete = item;
    },
    openUserCategoryWarning (item) {
      this.showUserCategoryDelete = true;
      this.idCategory.push(item);
    },
    openUserSkillsWarning (item) {
      this.showUserSkillDelete = true;
      this.idSkill.push(item);
    },
    openCommentWarning (item, comment) {
      this.showCommentDelete = true;
      this.postIdToDelete = item;
      this.commentIdToDelete = comment;
    },
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    deleteUserCategory () {
      this.loadingUserCategory = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/categories/delete`;
      axios
        .delete(url, {
          data: {
            user_id: this.currentUser.id,
            category_ids: this.idCategory
          }
        })
        .then(() => {
          this.updateUser(this.currentUser.id);
          this.updatePercentage(this.currentUser.id);
          this.loadingUserCategory = false;
          this.idCategory = [];
        })
        .catch((error) => {
          this.loadingUserCategory = false;
          this.idCategory = [];
          this.showError(error);
        });
      this.onDeleteUserCategoryClose();
    },
    deleteUserSkill () {
      this.loadingUserSkill = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/skills/delete`;
      axios
        .delete(url, {
          data: {
            user_id: this.currentUser.id,
            skill_ids: this.idSkill
          }
        })
        .then(() => {
          this.updateUser(this.currentUser.id);
          this.updatePercentage(this.currentUser.id);
          this.loadingUserSkill = false;
          this.idSkill = [];
        })
        .catch((error) => {
          this.loadingUserSkill = false;
          this.idSkill = [];
          this.showError(error);
        });
      this.onDeleteUserSkillClose();
    },
    deletePost () {
      this.loadingPosts = true;
      const url =
        process.env.VUE_APP_BASE_URL + '/api/posts/' + this.postIdToDelete;
      axios
        .delete(url)
        .then(() => {
          this.updateUser(this.currentUser.id);
          this.updatePercentage(this.currentUser.id);
          this.posts = this.posts.filter(item => item.id !== this.postIdToDelete);
          this.loadingPosts = false;
        })
        .catch((error) => {
          this.loadingPosts = false;
          this.showError(error);
        });
      this.onDeleteClose();
    },
    verifyVideoSize (event) {
      this.file = event;

      if (this.file.size > (20 * 1024 * 1024)) {
        this.$swal.fire({
          title: this.$t('ohNo'),
          text: this.$t('videoFileSizeToBig'),
          confirmButtonText: this.$t('accept')
        });

        this.url_video = null;
      }
    },
    getPostHour (hour) {
      const originalDate = new Date(hour);
      return moment(moment.utc(originalDate)).format('DD/MM/YYYY HH:mm');
    },
    getPosts (more) {
      if (!this.currentUser) {
        return;
      }

      if (more) {
        this.page++;
      }
      this.loadingPostsList = true;
      const url = process.env.VUE_APP_BASE_URL + `/api/posts?filters=user_id=${this.currentUser.id}&page=${this.page}&sortBy=id,desc`;
      axios.get(url).then((response) => {
        this.posts = this.posts.concat(response.data.data);
        if (this.userLoggedIn) {
          this.posts = this.posts.map(post => {
            if (this.userLoggedIn) {
              post.has_like = !!post.liked_by_users.find(i => i === this.userLoggedIn.id);
            }

            return post;
          });
        }
        this.loadingPostsList = false;
        this.hideMorePostsBtn = (this.page === response.data.last_page);
      }).catch((error) => {
          this.showError(error);
          this.loadingPostsList = false;
        });
    },
    resetPostForm () {
      this.title = '';
      this.content = null;
      this.url_image = null;
      this.url_video = null;
    },
    getFormData () {
      const formData = new FormData();
      if (this.url_image != null) {
        formData.append('url_image', this.url_image);
      }
      if (this.url_video != null) {
        formData.append('url_video', this.url_video);
      }
      formData.set('title', this.title);
      formData.set('content', this.content);
      return formData;
    },
    onAddCallback () {
      this.resetUserImage();
    },
    createPost () {
      this.contentErrors = [];
      if (!this.content) {
        this.contentErrors.push(this.$t('contentRequired'));
      } else {
        this.loading = true;
        const formData = this.getFormData();
        const url = process.env.VUE_APP_BASE_URL + '/api/posts';
        axios
          .post(url, formData)
          .then((response) => {
            this.sendDataPostLayerInfo();
            this.resetPostForm();
            this.posts = [response.data.data].concat(this.posts);
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error, false, false, false, this.$t('imageSizeWarning'));
          });
      }
    },
    newProfileImage () {
      this.showAddImage = true;
    },
    newBanner () {
      this.showDialogUserBanner = true;
    },
    onAddClose () {
      this.showAdd = false;
    },
    showUserDialog () {
      this.showAdd = true;
    },
    onAddImageClose () {
      this.showAddImage = false;
    },
    onBannerDialogClose () {
      this.showDialogUserBanner = false;
    },
    onAddCloseCategory () {
      this.showAddCategory = false;
    },
    onAddCloseSkill () {
      this.showAddSkill = false;
    },
    addCategoryToUser () {
      this.showAddCategory = true;
    },
    banUser () {
      this.showBanDialog = true;
    },
    addSkillToUser () {
      this.showAddSkill = true;
    },
    sendDataLayerInfo () {
      if (this.isSearchedUser) {
        const searchUserData = this.currentUser;
        this.trackUserData('load_content', {
          pageType: 'profile',
          prosker: searchUserData.first_name + ' ' + searchUserData.last_name,
          proskerId: searchUserData.id,
          haveBackground: searchUserData.banner_img ? 'yes' : 'no',
          havePhoto: searchUserData.profile_img ? 'yes' : 'no',
          postsNumber: searchUserData.posts_quantity,
          jobsNumber: searchUserData.count_works_done,
          reviewsRating: searchUserData?.rating,
          categories: searchUserData.category_name,
          modalidades: searchUserData.skill_name
        });
      }
    },
    sendDataPostLayerInfo () {
      this.trackUserData('profile_create_publication', {
        publication_title: this.title
      });
    },
    sendDataEditProfileClickLayerInfo () {
      this.trackUserData('profile_click', {
        page_title: 'profile'
      });
    }
  },
  mounted () {
    this.sendDataLayerInfo();
}
};
</script>
<style scoped>
  .mp-button {
    background:#03a9f4 !important;
    color: #fff !important;
  }

  .main-row {
    gap: 40px;
  }

  .round {
    border-radius: 32px;
  }

  h3.title,
  h4.title {
    color: #3F44A6;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .title.inner {
    margin-bottom: 9px;
  }

  .info-row {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }

  .info-row h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .info-row p {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 0 14px;
  }

  hr.divider {
    border-color: #e8e8e8;
    margin: 32px 0;
  }

  .normal-text {
    font-size: 16px;
  }

  .details-card {
    margin-bottom: 40px;
  }

  .input-text {
    border-radius: 16px;
  }

  .input-text >>> .v-input__slot {
    min-height: 55px;
  }

  .input-text >>> fieldset {
    color: #BBBBBB !important;
  }

  .profile-buttons {
    margin-top: 28px;
  }

  .profile-buttons > * {
    margin-right: 24px;
  }

  .button-select {
    position: relative;
    height: 40px;
    width: 100px;
    border-radius: 100px;
    margin-top: 20px;
    background: #59D79D;
    color: #3F44A6;
    cursor: pointer;
    margin-left: 12px;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
    top: 20px;
    right: 32px;
  }

  .margin-y {
    margin-top: -28px;
    margin-bottom: -32px;
  }

  .input-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 10;
  }

  .input-comment {
    display: flex;
    gap: 8px;
  }

  .input-comment input {
    border-radius: 16px;
    border: 1px solid #bbb;
    font-size: 15px;
    padding: 18px;
    width: 100%;
  }

  .send-comment-btn {
    height: 55px !important;
    min-width: 55px !important;;
    width: 55px !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .comments-wrapper {
    padding: 32px 0 1px;
  }

  .comment {
    height: 52px;
    width: auto;
    display: flex;
    justify-content: space-between;
    margin: 0 40px 32px;
  }

  .comment:not(:last-child) {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 32px;
  }

  .input-enter {
    width: 260%;
  }

  .comment-name {
    font-weight: bold;
    color: #3F44A6;
    font-size: .9rem;
    margin-bottom: 0;
  }

  .comment-text {
    font-size: .8rem;
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    .input-enter {
      width: 100%;
    }
  }
</style>
<style scoped lang="scss">
.sm {
  &.main-row {
    gap: 0;
  }

  .profile-buttons {
    margin-top: 32px;

    > .v-btn {
      width: 100%;
      margin: 6px 0;
    }
  }
}
</style>
