import { io } from 'socket.io-client';
import { GlobalEventEmitter } from '@/misc/globalEventEmitter';

const socket = io(process.env.VUE_APP_CHAT_SERVER, { autoConnect: false });

socket.onAny((event, ...args) => {
  console.log(event, args);
});

socket.on('connectedUsers', (data) => {
  GlobalEventEmitter.$emit('connectedUsers', data);
});

socket.on('userConnected', (data) => {
  GlobalEventEmitter.$emit('userConnected', data);
});

socket.on('privateMessage', (data) => {
  GlobalEventEmitter.$emit('newMessage', data);
});

socket.on('userDisconnected', (data) => {
  GlobalEventEmitter.$emit('userDisconnected', data);
});

socket.on('session', ({ sessionId, userId }) => {
  // attach the session ID to the next reconnection attempts
  socket.auth = { sessionId };
  // store it in the localStorage
  localStorage.setItem('sessionId', sessionId);
  // save the ID of the user
  socket.userId = userId;
});

export default socket;
