<template>
  <v-main class="container-prosker">
    <v-data-table
      :options.sync="pagination"
      :server-items-length="pagination.totalItems"
      :loading="loadingTable"
      :search="search"
      :headers="headers"
      :footer-props="{itemsPerPageText: $t('rowsPerPage')}"
      :items="servicesData"
      item-key="id"
      class="services-table"
    >
      <!-- <template v-slot:top>
        <v-toolbar flat>
          <custom-text-field
            class="search-box"
            :placeholder="$t('search')"
            icon="mdi-magnify"
            flat
            full-width
            hide-details
            v-model="search"
            color="main_text_color"
          />

          <div>
            {{ $t('rowsPerPage') }}
            <custom-select-field
              :items="[10, 20, 30]"
              flat
              :value.sync="pagination.itemsPerPage"
              color="main_text_color"
            />
          </div>
        </v-toolbar>
      </template> -->

      <template v-slot:no-data>
        <v-alert :value="true" color="" icon="">
          <span class="">
            {{ $t("noData") }}
          </span>
        </v-alert>
      </template>

      <template v-slot:no-results>
        <v-alert :value="true" color="" icon="">
          <span>
            {{ $t('yourSearchFor') }} "{{ search }}" {{ $t('foundNoResults') }}
          </span>
        </v-alert>
      </template>

      <template v-slot:item.name="{ value }">
        <span class="caption mobile-font">{{ value }}</span>
      </template>
      <template v-slot:item.updated_at="{ value }">
        <span class="caption mobile-font">{{ getDateFormat(value) }}</span>
      </template>
      <template v-slot:item.schedule_required="{ value }">
        <span class="caption mobile-font">{{ value ? $t('yes') : $t('no') }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon @click="$emit('editItem', item)" >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-main>
</template>
<script>
import axios from 'axios';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import getStatusJobsName from '@/components/socialvue/mixins/getStatusJobsName';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import userDataMixin from '@/mixins/userDataMixin';

export default {
  name: 'UserServicesListing',
  components: {
  },
  props: {
    isMyServices: {
      type: Boolean,
      default: false
    }
  },
  mixins: [errorDialog, getStatusJobsName, userDataMixin],
  emits: ['editItem'],
  data () {
    return {
      servicesData: [],
      showJobs: true,
      missingData: [],
      isProsker: false,
      headersMap: [],
      headers: [],
      count: 0,
      isDirty: false,
      pagination: { totalItems: 0, itemsPerPage: 10, page: 1 },
      loadingTable: false,
      search: '',
      showAdd: false,
      isCanceled: false,
      showHelpDialog: false,
      editedItem: {},
      loading: false,
      showReject: false,
      loadingReject: false,
      id: 0,
      status: 20,
      itemIdCancel: 0,
      itemIdJob: 0,
      showCancellationDialog: false,
      isMyServicesData: null,
      currencyCode: '',
      tabIndex: 0,
      preventFirstPaginationEventFlag: 0
    };
  },
  created () {
    this.callBack();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  watch: {
    'search': {
      handler () {
        this.resetPaginationForSearch(this);
      },
      deep: true
    },
    'pagination': {
      handler () {
        if (!this.isDirty) {
          if (this.preventFirstPaginationEventFlag < 2) { // number because this list are call 2 times on the first render remove that on future
            this.preventFirstPaginationEventFlag++;
          } else {
            this.list();
          }
        }
      },
      deep: true
    },
    $route () {
      this.callBack();
    }
  },
  methods: {
    callBack () {
      this.list();
      this.fillHeadersMap();
    },
    setIsMyServicesData () {
      this.isMyServicesData = window.location.pathname.split('/')[2] === 'mis-servicios' || this.isMyServices;
    },
    fillHeadersMap () {
      this.headersMap = [];
      this.headersMap = [
        { text: this.$t('title'), value: 'name', width: '20%' },
        { text: this.$t('description'), value: 'description' },
        { text: this.$t('price'), value: 'price' },
        // { text: this.$t('payment_required'), value: 'payment_required' },
        { text: this.$t('requireSchedule'), value: 'schedule_required', width: '150px' },
        { text: this.$t('lastUpdate'), value: 'updated_at', width: '12%' },
        { text: this.$t('actions'), value: 'action', sortable: false, width: '100px' }
      ];

      this.headers = Object.values(this.headersMap);
    },
    resetPagination () {
      if (!this.isDirty) {
        this.pagination = { totalItems: 0, itemsPerPage: 10, page: 1, sortBy: ['id'] };
      }
    },
    resetPaginationForSearch: _.debounce((self) => {
      if (!self.isDirty) {
        self.resetPagination();
      }
    }, 500),
    list () {
      if (!this.currentUser) {
        return;
      }

      // console.log('list', this.pagination);

      this.isDirty = true;
      this.servicesData = [];
      this.loadingTable = true;
      const servicesUrl = `${process.env.VUE_APP_BASE_URL}/api/services?search=${
        this.search
      }&per_page=${
        this.pagination.itemsPerPage
      }&page=${
        this.pagination.page
      }&sortBy=updated_at,desc&filters=user_id=${this.currentUser.id}`;

      axios.get(servicesUrl).then((response) => {
        this.isDirty = false;
        this.servicesData = response.data.data;
        this.pagination.totalItems = response.data.total;
        this.loadingTable = false;
      }).catch((error) => {
        this.isDirty = false;
        this.loadingTable = false;
        this.showError(error);
      });
    },
    newItem () {
      this.reset();
      this.showAdd = true;
      this.editedItem.isEdit = false;
      this.editedItem.isClient = !this.isMyServicesData;
    },
    onAddCallback () {
      this.list();
    },
    onAddClose () {
      this.reset();
      this.editedItem = {};
      this.showAdd = false;
      this.editedItem.isEdit = false;
      this.editedItem.isClient = false;
    },
    getDateFormat (date) {
      const originalDate = new Date(date);
      return moment(moment.utc(originalDate)).format('DD-MM-YYYY');
    },
    reset () {
      this.editedItem = {};
      this.editedItem.client = '';
      this.editedItem.review = '';
      this.editedItem.end_date = '';
      this.editedItem.start_date = '';
      this.editedItem.price = '';
      this.editedItem.end_date_format = '';
      this.editedItem.start_date_format = '';
      this.editedItem.description = '';
      this.editedItem.title = '';
      this.editedItem.payment_required = false;
    }
  }
};
</script>
<style scoped>
  .search-box {
    max-width: 550px;
  }

  .services-table {
    border-radius: 32px;
    overflow: hidden;
    padding: 40px 32px;
  }

  .services-table :deep(.v-data-table__mobile-row__header),
  .services-table :deep(th) {
    color: #6B71F2 !important;
    font-size: 14px !important;
    font-weight: 700;
  }

  .services-table.v-data-table :deep(.v-data-table-header-mobile tr:last-child > th) {
    border-bottom: 0;
  }

  .services-table :deep(.v-data-table__mobile-table-row) {
    margin-top: 32px;
  }

  .services-table :deep(.v-data-table__mobile-row:last-child) {
    padding-bottom: 32px;
  }

  @media (max-width: 599px) {
    .mobile-font {
      font-size: .9rem !important;
    }
  }

  @media (max-width: 540px) {
    .title-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .service-mobile {
      width: 100%;
      margin: 24px 0;
    }

    .service-mobile >>> .v-btn {
      height: auto;
      padding: 14px;
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    .title {
      overflow: visible !important;
    }
  }
</style>
