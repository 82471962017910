var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[{ 'full-width': _vm.fullWidth }]},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.customLabel),expression:"customLabel"}],staticClass:"pb-0 mb-0"},[_vm._v(_vm._s(_vm.customLabel))]),_c('div',[_c('v-select',_vm._b({class:[
        'custom-select-field',
        {
          flat: _vm.flat,
        },
        _vm.cssClasses
      ],attrs:{"items":_vm.items,"filled":!!_vm.flat},on:{"input":_vm.updateValue}},'v-select',_vm.$attrs,false))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }