<template>
  <v-row>
    <v-col cols="12" md="12">
    <div>
      <v-sheet
        tile
        class="d-flex justify-content-between mt-5 border-bottom border-primary"
      >
        <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <h3 v-if="$refs.calendar" class="text-center py-3">{{ $refs.calendar.title }}</h3>
        <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>

      <v-sheet>
        <div>
          <div class="mb-6 col-12 text-center p-0">
            <p class="text-overline mt-0 mb-2">Referencias</p>
            <v-badge
              color="bg-green-prosk"
              :content="$t('accepted')"
              inline
            ></v-badge>
            <v-badge
              color="blue"
              :content="$t('pendingApproval')"
              inline
            ></v-badge>
          </div>
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            :locale="$i18n.locale"
            @change="getEvents"
            @click:event="showEvent"
          ></v-calendar>
        </div>
      </v-sheet>
    </div>
    </v-col>
  </v-row>
  </template>
  <script>
  import axios from 'axios';
  import userDataMixin from '@/mixins/userDataMixin';
export default {
    name: 'UserCalendarClient',
    mixins: [userDataMixin],
    data () {
      return {
        type: 'month',
        types: [
          { value: 'month', text: this.$t('month') },
          { value: 'week', text: this.$t('week') },
          { value: 'day', text: this.$t('day') }
        ],
        calendarStartDate: '',
        calendarEndDate: '',
        mode: 'stack',
        weekday: [1, 2, 3, 4, 5, 6, 0], // Mon to Sun
        loading: true,
        profileUserId: null,
        value: '',
        events: [],
        isMyCalendar: true,
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party']
      };
    },
    computed: {
      currentUser () {
        return this.$store.getters['auth/currentUser'];
      }
    },
    created () {
      const data = JSON.parse(this.$route.params.query);
      this.profileUserId = data.id;
      this.isMyCalendar = this.profileUserId === this.currentUser.id;
    },
    methods: {
        getEventName (event) {
          if (this.isMyCalendar) {
            const clientName = this.currentUser.id === event.client.id ? `${event.prosker.first_name} ${event.prosker.last_name}` : `${event.client.first_name} ${event.client.last_name}`;
            return (event.job_id) ? event.job.title : this.$t('eventWith', { clientName: clientName });
          }

          return (event.client_id === this.currentUser.id) ? this.$t('myEvent') : this.$t('someEvent');
        },
        getEvents ({ start, end }) {
          this.calendarStartDate = start;
          this.calendarEndDate = end;
          this.loading = true;
          const clientId = `,client_id=${this.profileUserId}|`
          const url = `${process.env.VUE_APP_BASE_URL}/api/events?filters=event_date>=${start.date},event_date<=${end.date}${clientId}`;
          axios
            .get(url)
            .then((response) => {
                this.events = response.data.data.map(event => {
                  event.name = this.getEventName(event);
                  event.start = new Date(`${event.event_date}T${event.start_time}`);
                  event.end = new Date(`${event.event_date}T${event.end_time}`);
                  event.color = (!event.approved) ? 'blue' : (event.approved === -1) ? 'display-none' : 'bg-green-prosk';
                  event.timed = true;
                  return event;
                });

                this.loading = false;
            })
            .catch((error) => {
                this.loading = false;
                this.showError(error);
            });
        },
        getEventColor (event) {
          return event.color;
        },
        rnd (a, b) {
          return Math.floor((b - a + 1) * Math.random()) + a;
        },
        showEvent (eventData) {
          const event = eventData.event;
          this.selectedDate = event.event_date;
          this.selectingEvent = true;
          const proskerId = (this.isMyCalendar) ? this.currentUser.id : this.profileUserId;
          if (!this.isMyCalendar && event.client_id !== this.currentUser.id) {
            this.showError({ message: this.$t('cannotReadEvent') });
          } else {
            this.getProskerAvailability(proskerId, this.selectedDate)
            .then((response) => {
                this.selectedEvent = {
                  id: event.id,
                  is_new: false,
                  event_date: event.event_date,
                  prosker_id: event.prosker_id,
                  client_id: event.client_id,
                  job_id: event.job_id,
                  comments: event.comments,
                  start_time: event.start_time.slice(0, -3),
                  end_time: event.end_time.slice(0, -3),
                  approved: event.approved.toString(),
                  available_times: this.parseAvailableTimes(response),
                  client: event.client
                };
                this.eventDialog = true;
            })
            .catch((error) => {
                this.loading = false;
                this.showError(error);
            });
          }
      },
      sendDataLayerInfo () {
        this.trackUserData('load_content', {
          pageType: 'profile',
          pageSubType: 'schedule',
          prosker: this.currentUser.first_name + ' ' + this.currentUser.last_name,
          proskerId: this.currentUser.id,
          haveBackground: this.currentUser.banner_img ? 'yes' : 'no',
          havePhoto: this.currentUser.profile_img ? 'yes' : 'no',
          postsNumber: this.currentUser.posts_quantity,
          jobsNumber: this.currentUser.count_works_done,
          reviewsRating: this.currentUser?.rating,
          categories: this.currentUser.category_name,
          modalidades: this.currentUser.skill_name
        });
    }
    }
  };
</script>
