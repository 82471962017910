<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader
        v-for="index in 5"
        :key="index"
        class="review-item"
        type="list-item-avatar, list-item-three-line"
      ></v-skeleton-loader>
    </div>
    <div v-if="!loading && reviews.length !== 0">
      <user-review-item
        v-for="(item, x) in reviews"
        :key="x"
        class="review-item"
        :user="item.jobs.client_user"
        :title="item.title"
        :content="item.content"
        :rating="item.rating"
        :created_at="item.created_at"
      ></user-review-item>

      <div class="pagination" v-if="lastPage !== 1">
        <v-pagination
          v-model="reviewsPage"
          color="#59D79D"
          :length="lastPage"
          circle
        ></v-pagination>
      </div>
    </div>
    <div v-if="!loading && reviews.length === 0">
      <alert :message="$t('userHasNotReviews')"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import UserReviewItem from './UserReviewItem.vue';

export default {
  name: 'UserReviewsCore',
  components: { Alert, UserReviewItem },
  props: ['currentUser'],
  data () {
    return {
      reviewsPage: 1,
      lastPage: 1,
      reviews: [],
      loading: true
    };
  },
  watch: {
    reviewsPage: function (value) {
      if (value) {
        this.getReviews();
      }
    }
  },
  created () {
    this.getReviews();
  },
  methods: {
    getReviews () {
      if (!this.currentUser) {
        return;
      }

      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/reviews?filters=jobs.prosker_user_id=${this.currentUser.id}|&page=${this.reviewsPage}&per_page=5&sortBy=created_at,desc`;
      axios
        .get(url)
        .then((response) => {
          this.reviews = response.data.data;
          this.lastPage = response.data.last_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    }
  }
};
</script>
<style scoped>
.review-item:not(:last-child) {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 28px;
}

.pagination {
  margin-top: 24px;
  justify-content: center;
}
</style>
