<template>
<details-card cardClass="m-0 service-card" >
  <v-card-title class="title">
    <prosk-title>
      {{ data.name }}
    </prosk-title>
    <v-chip
      v-if="data.category"
      class="ml-auto main_text_color--text primary_light"
    >
      {{ data.category}}
    </v-chip>
  </v-card-title>
  <v-card-text>
    <p class="dark_blue--text"><strong>{{ $t('description') }}</strong></p>
    <p class="hint_text_color--text">
      {{ data.description }}
    </p>
  </v-card-text>

  <slot></slot>

  <v-divider v-if="showFooter" />
  <v-card-actions v-if="showFooter" class="dialog-actions dark_blue--text">
    <v-row>
      <v-col>
        <strong>
          {{ $t('requireSchedule') }}
        </strong>
      </v-col>
      <v-col cols="3" class="text-right">
        <span class="require-appointment light_grey">
          {{ data.schedule_required ? $t('yes') : $t('no') }}
        </span>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        {{ formatPrice(data.price) }}
      </v-col>
      <v-col cols="3" class="text-right">
        <general-button
          @click="() => $emit('buyService', data)"
          secondary
        >
          {{ $t('buy') }}
        </general-button>
        <general-button
          @click="onMessage"
          secondary
        >
          {{ $t('message') }}
        </general-button>
      </v-col>
    </v-row>
  </v-card-actions>
</details-card>
</template>
<script>
import ProskTitle from '@/components/socialvue/markup/ProskTitle';
import DetailsCard from '@/components/socialvue/cards/DetailsCard';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import { formatPrice } from '@/misc/utils';
import conversationsMixin from '@/mixins/conversationsMixin';

export default {
  name: 'UserServiceCardItem',
  components: {
    DetailsCard,
    GeneralButton,
    ProskTitle
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  emits: ['buyService'],
  mixins: [conversationsMixin],
  methods: {
    formatPrice
  }
};
</script>
<style scoped>
.service-card {
  font-size: 16px;
  line-height: 30px;
}

.dialog-actions {
  display: block;
  padding-bottom: 40px !important;
}

.require-appointment {
  border-radius: 8px;
  padding: 8px 12px;
}
</style>
