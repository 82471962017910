<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showAdd"
    width="500"
    persistent
    scrollable
    @keydown.esc="onClose"
    @keydown.enter="save"
  >
    <template v-slot:activator="{ on }">
      <general-button
        :action="onNewClicked"
        small
      >
        {{ item.isEdit ? $t("editAddress") : $t("createAddress") }}
      </general-button>
    </template>

    <details-card scrollable>
      <v-card-title>
        <prosk-title>
          {{ item.isEdit ? $t("editAddress") : $t("createAddress") }}
        </prosk-title>
      </v-card-title>
      <v-card-text>
        <div class="address-fields">
          <custom-text-field
            :customLabel="$t('domicile')"
            v-model="line_1"
            :hide-details="domicileErrors.length === 0"
            :error-count="domicileErrors.length"
            :error-messages="domicileErrors"
          ></custom-text-field>
          <custom-text-field
            :customLabel="$t('postalCode')"
            v-model="postal_code"
            customType="number"
            :hide-details="postalCodeErrors.length === 0"
            :error-count="postalCodeErrors.length"
            :error-messages="postalCodeErrors"
          ></custom-text-field>
          <custom-select-field
            :customLabel="$t('city')"
            v-model="cityId"
            :items="cities"
            :hide-details="cityErrors.length === 0"
            :error-count="cityErrors.length"
            :error-messages="cityErrors"
          ></custom-select-field>
          <custom-text-field
            :customLabel="$t('neighborhood')"
            v-model="neighborhood"
            :hide-details="neighborhoodErrors.length === 0"
            :error-count="neighborhoodErrors.length"
            :error-messages="neighborhoodErrors"
          ></custom-text-field>
          <!--<custom-text-field
              :customLabel="$t('country')"
              v-model="country"
              color="teal"
              :hide-details="countryErrors.length === 0"
              :error-count="countryErrors.length"
              :error-messages="countryErrors"
          ></custom-text-field>-->
          <v-checkbox
            v-model="is_default"
            :hide-details="checkedError.length === 0"
            :error-count="checkedError.length"
            :error-messages="checkedError"
            :label="$t('isDefault')"
          >
          </v-checkbox>
        </div>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button :action="onClose" outlined :message="$t('cancel')"/>
        <general-button :action="save" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import GeneralButton from '../buttons/GeneralButton.vue';
import DetailsCard from '../cards/DetailsCard.vue';
import ProskTitle from '../markup/ProskTitle.vue';
import CustomTextField from '../customTextField/CustomTextField.vue';
import CustomSelectField from '../customSelectField/CustomSelectField.vue';

export default {
  name: 'UserAddressesDialog',
  created () {
      this.setImportedConstants();
      this.getCities();
  },
  components: {
    DetailsCard,
    ProskTitle,
    CustomTextField,
    CustomSelectField,
    GeneralButton
  },
  props: ['showAdd', 'onClose', 'item', 'newItem'],
  mixins: [errorDialog],
  data () {
    return {
      postalCodeErrors: [],
      cityErrors: [],
      neighborhoodErrors: [],
      checkedError: [],
      domicileErrors: [],
      countryErrors: [],
      postal_code: null,
      neighborhood: null,
      line_1: null,
      cityId: null,
      country: null,
      is_default: false,
      loading: false,
      cities: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  watch: {
    showAdd: function (value) {
      if (value && this.item && this.item.isEdit) {
        this.postal_code = this.item.postal_code;
        this.cityId = this.item.city_id;
        this.country = this.item.country;
        this.neighborhood = this.item.neighborhood;
        this.line_1 = this.item.line_1;
        this.is_default = this.item.is_default;
      }
    }
  },
  methods: {
    setImportedConstants () {
        this.$options.location = process.env.VUE_APP_LOCATION;
    },
    getCities () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/cities`;
      axios.get(url).then((response) => {
        response.data.data.forEach((city) => {
          this.cities.push({ text: city.name, value: city.id });
        });
      }).catch((error) => {
        this.showError(error);
      });
    },
    onNewClicked () {
      this.resetErrors();
      this.reset();
      this.newItem();
    },
    resetErrors () {
      this.postalCodeErrors = [];
      this.cityErrors = [];
      this.neighborhoodErrors = [];
      this.checkedError = [];
      this.countryErrors = [];
      this.domicileErrors = [];
    },
    reset () {
      this.postal_code = null;
      this.loading = false;
      this.cityId = null;
      this.neighborhood = null;
      this.country = null;
      this.line_1 = null;
      this.is_default = false;
    },
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    save () {
      this.resetErrors();
      if (!this.line_1) {
        this.domicileErrors.push(this.$t('fieldDomicileRequired'));
      }
      if (!this.neighborhood) {
        this.neighborhoodErrors.push(this.$t('fieldNeighborhoodRequired'));
      }
      if (!this.postal_code) {
        this.postalCodeErrors.push(this.$t('fieldPostalCodeRequired'));
      }
      if (!this.cityId) {
        this.cityErrors.push(this.$t('fieldCityRequired'));
      }
      if (this.cityId && this.postal_code && this.neighborhood && this.line_1) {
        this.loading = true;
        const data = {
          user_id: this.currentUser.id,
          postal_code: this.postal_code,
          neighborhood: this.neighborhood,
          line_1: this.line_1,
          city_id: this.cityId,
          country: this.country,
          is_default: this.is_default
        };
        if (!this.item.isEdit) {
          const url = `${process.env.VUE_APP_BASE_URL}/api/addresses`;
          axios
            .post(url, data)
            .then(() => {
              this.loading = false;
              this.onClose();
              this.updateUser(this.currentUser.id);
              this.updatePercentage(this.currentUser.id);
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error);
            });
        } else {
          const urlEdit = `${process.env.VUE_APP_BASE_URL}/api/addresses/`;
          axios
            .put(urlEdit + this.item.id, data)
            .then(() => {
              this.updateUser(this.currentUser.id);
              this.updatePercentage(this.currentUser.id);
              this.loading = false;
              this.onClose();
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error);
            });
        }
      }
    }
  }
};
</script>
<style scoped>
.address-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
