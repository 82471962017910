<template>
  <details-card>
    <template v-slot:title>
      {{ $t("userReviews") }}
    </template>

    <template v-slot:content>
      <div v-if="loading">
        <v-skeleton-loader
          v-for="index in 5" :key="index"
          class="review-item"
          type="list-item-avatar, list-item-three-line"
        ></v-skeleton-loader>
      </div>

      <div v-if="!loading && reviews.length !== 0">
        <user-review-item
          v-for="(item, x) in reviews" :key="x"
          class="review-item"
          :user="item.jobs.client_user"
          :title="item.title"
          :content="item.content"
          :rating="item.rating"
          :created_at="item.created_at"
        ></user-review-item>
      </div>

      <div v-if="!loading && reviews.length === 0">
        <v-col cols="12" class="my-3">
          <v-row>
            <v-col cols="12">
              <alert :message="$t('userHasNotReviews')"/>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </template>
  </details-card>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import userDataMixin from '@/mixins/userDataMixin';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import UserReviewItem from './UserReviewItem.vue';

export default {
  name: 'UserReviews',
  components: { Alert, DetailsCard, UserReviewItem },
  props: ['currentUser', 'isSearchedUser'],
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      reviews: [],
      loading: true,
      totalReviews: 0
    };
  },
  created () {
    this.getReviews();
  },
  methods: {
    getDateFormat (date) {
      const serverDate = new Date(date);
      const myDate = new Date();
      const difference = myDate - serverDate;
      return moment(moment.utc(difference)).format('D');
    },
    getReviews () {
      if (!this.currentUser) {
        return;
      }

      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/reviews?filters=jobs.prosker_user_id=${this.currentUser.id}|&sortBy=created_at,desc`;
      axios
          .get(url)
          .then((response) => {
            this.totalReviews = response.data.total;
            this.sendDataLayerInfo();
            this.reviews = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.showError(error);
            this.loading = false;
          });
    },
    sendDataLayerInfo () {
      this.trackUserData('load_content', {
      pageType: 'my_account',
      pageSubType: 'reviews',
      reviewsRating: this.currentUser?.rating,
      reviewNumbers: this.totalReviews
    });
    }
  }
};
</script>
<style scoped>
.review-item:not(:last-child) {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 28px;
}
</style>
