<template>
  <v-expansion-panels v-model="panel" multiple :mandatory="!$vuetify.breakpoint.mobile">
    <v-expansion-panel class="conversations-list-panel">
      <v-expansion-panel-header class="conversations-list-header">
        <div class="chat-searchbar">
          <custom-text-field
            :placeholder="$t('searchProskerForMessage')"
            icon="mdi-magnify"
            icon-position="prepend"
            v-model="search"
            @input="filterConversations"
            flat
            full-width
          />
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="conversations-list-expansion">
        <v-list v-if="!loadingConversations">
          <v-list-item-group v-if="conversations.length" color="title_highlight_color">
            <v-list-item
              v-for="(item, i) in conversations"
              :key="i"
              class="conversation-item"
              @click="triggerConversationSelection(item)"
            >
              <div class="d-flex align-items-center w-100">
                <v-badge
                  bordered
                  bottom
                  color="primary"
                  dot
                  offset-x="13"
                  offset-y="10"
                  :value="item.hasNewMessages"
                >
                  <v-avatar width="2.5rem" height="2.5rem">
                    <img
                      :src="item.img_url"
                      class="rounded-circle"
                      :style="item.online ? 'border: 2px solid #53FAA5' : ''"
                      alt="user"
                    />
                  </v-avatar>
                </v-badge>

                <div class="chat-sidebar-name ml-2">
                  <div class="chat-sidebar-name-top">
                    <div class="chat-name secondary--text">
                      <div class="text-truncate">
                        {{ item.title }}
                      </div>
                    </div>
                    <div class="chat-ts">
                      {{  getMessageTime(item.last_message_timestamp) }}
                    </div>
                  </div>
                  <!-- <div class="chat-sidebar-last-message hint_text_color--text">
                    <div class="text-truncate">
                      Lorem ipsum! dol amet
                      Lorem ipsum! dol amet consectetur Lorem ipsum! dol amet consectetur
                    </div>
                  </div> -->
                </div>
              </div>
            </v-list-item>

            <div class="text-center my-4" v-if="!hideLoadMoreConversationsButton">
              <v-btn icon color="green" @click="loadMoreConversations">
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </div>
          </v-list-item-group>
          <v-list-item-group v-else>
            <v-list-item>
              <div class="d-flex align-items-center">
                <h4 class="mb-0">{{ $t("noResults") }}</h4>
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-list v-if="loadingConversations">
          <v-skeleton-loader
            v-for="index in 5"
            :key="index"
            v-bind="skeletonAttrs"
            type="list-item-avatar"
          />
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import _debounce from 'lodash/debounce'

import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { USER_TYPE_PROSKER, FEATURE_JOBS } from '@/misc/constants';
import socket from '@/misc/socket';
import { isFeatureEnabled } from '@/misc/featureFlagService';
import { GlobalEventEmitter } from '@/misc/globalEventEmitter';
import CustomTextField from '@/components/socialvue/customTextField/CustomTextField.vue';
import moment from 'moment';

export default {
  props: ['user', 'selectedConversationId', 'message'],
  name: 'ConversationList',
  mixins: [errorDialog],
  components: { CustomTextField },
  data () {
    return {
      search: '',
      loadingConversations: true,
      skeletonAttrs: {
        class: 'mb-6',
        elevation: 0
      },
      hideLoadMoreConversationsButton: false,
      conversationsPage: 1,
      panel: [0],
      socket: null,
      conversations: [],
      selectedConversation: null,
      emptyConversations: false
    };
  },
  watch: {
     message: function (newVal, oldVal) {
      this.sendMessageToSocket(newVal);
    }
  },
  created () {
    this.fetchConversations('', true);
    this.setImportedConstants();

    GlobalEventEmitter.$on('newMessage', (data) => {
      this.handlePrivateMessage(data);
    });

    GlobalEventEmitter.$on('userConnected', (data) => {
      this.handleUserConnected(data);
    });

    GlobalEventEmitter.$on('connectedUsers', (data) => {
      this.handleConnectedUsers(data);
    });

    GlobalEventEmitter.$on('userDisconnected', (data) => {
      this.handleDisconnectedUser(data);
    });
  },
  destroyed () {
    socket.off('connect_error');
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    getMessageTime (messageDate) {
      const originalDate = new Date(messageDate);
      const hour = moment(moment.utc(originalDate)).fromNow(true);
      return hour;
    },
    sendMessageToSocket (data) {
       socket.emit('privateMessage', {
        content: data.content,
        to: data.userId
      });
    },
    handleConnectedUsers (users) {
      const conversations = this.conversations.map(item => {
        const connectedUser = users.find(usr => (usr.connected && usr.userId === item.recipientUserId));
        if (connectedUser) {
          item.online = true;
        }
        return item;
      });
      this.conversations = conversations;
    },
    handleDisconnectedUser (userId) {
       const conversations = this.conversations.map(item => {
        if (item.recipientUserId === userId) {
          item.online = false;
        }
        return item;
      });
      this.conversations = conversations;
    },
    handlePrivateMessage (data) {
      this.conversations = this.conversations.map(item => {
        if (item.recipientUserId === data.content.from_user_id) {
          item.hasNewMessages = true;
        }
        return item;
      });

      if (this.selectedConversation.recipientUserId === data.content.from_user_id) {
        this.$emit('new-message', { message: data.content });
      }
    },
    handleUserConnected (data) {
      // find the conversation with this user and set the socket id
      this.conversations = this.conversations.map(item => {
        if (item.recipientUserId === data.userId) {
          item.online = true;
        }
        return item;
      });
    },
    initSocket () {
      const sessionId = localStorage.getItem('sessionId');
      const currentUserId = this.currentUser.id;
      if (sessionId) {
        socket.auth = {
          sessionId: sessionId,
          currentUserId: currentUserId
        };
        socket.connect();
      } else {
        socket.auth = { currentUserId };
        socket.connect();
      }
      socket.on('connect_error', (err) => {
        if (err.message === 'invalid userId') {
          // TODO - manage disconnection
        }
      });
    },
    setImportedConstants () {
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.JOBS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_JOBS);
    },
    filterConversations: _debounce(function () {
        this.loadingConversations = true;
        this.conversationsPage = 1;
        if (!this.search) {
          this.conversations = [];
        }
        this.fetchConversations(this.search, false);
    }, 500),
    openConversation (conversation) {
      this.selectedConversation = conversation;
      this.conversations.find(item => item.id === conversation.id).hasNewMessages = false;
      this.$emit('open-conversation', { conversation: conversation });
    },
    loadMoreConversations () {
      this.conversationsPage++;
      this.fetchConversations('', false);
    },
    getConversationById (conversationId, callback) {
      let url = `${process.env.VUE_APP_BASE_URL}/api/conversations?filters=id=${conversationId}|`;
      axios
        .get(url)
        .then((response) => {
          if (response.data.data.length === 0) {
            throw new Error(this.$t('conversationNotFound'));
          }
          callback(response.data.data[0]);
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    triggerConversationSelection (conversation) {
      this.selectedConversation = conversation;
      this.loadingConversations = false;
      this.openConversation(this.selectedConversation);
    },
    selectCurrentConversation (conversations, conversationId) {
      let selectedConversation = null;
      if (conversationId > 0) {
        selectedConversation = conversations.find(item => item.id.toString() === conversationId);
        if (selectedConversation) {
          this.triggerConversationSelection(selectedConversation);
        } else {
          this.getConversationById(
            conversationId,
            (conversation) => {
              const processedConversation = this.processConversation(conversation);
              conversations.push(processedConversation);
              this.conversations = conversations;
              this.triggerConversationSelection(processedConversation);
            }
          );
        }
      } else {
        this.triggerConversationSelection(conversations[0]);
      }
    },
    processConversation (conversation) {
      const otherUser = conversation.users.find(usr => usr.id !== this.user.id);
      conversation.img_url = otherUser.profile_img ? otherUser.profile_img : require('@/assets/images/page-img/avatar.png');
      conversation.title = `${otherUser.first_name} ${otherUser.last_name}`;
      conversation.recipientUserId = otherUser.id;
      conversation.hasNewMessages = false;
      return conversation;
    },
    fetchConversations (query, creatingComponent) {
      let url = `${process.env.VUE_APP_BASE_URL}/api/conversations?filters=users.id=${this.user.id}|&page=${this.conversationsPage}`;
      if (query) {
        url = `${url}&search=${query}`;
      }
      axios
        .get(url)
        .then((response) => {
          if (creatingComponent && response.data.data.length === 0) {
            throw new Error(this.$t('emptyConversations'));
          }

          this.hideLoadMoreConversationsButton = (this.conversationsPage === response.data.last_page);
          if (query) {
            this.conversations = [];
          }
          this.conversations = this.conversations
          .concat(
            response.data.data.map(conversation => {
              return this.processConversation(conversation);
            })
          );

          if (creatingComponent) {
            // TODO - remove duplicates (for the case when a user is selected from user list and exists in a new page of the conversation list)
            this.selectCurrentConversation(this.conversations, this.selectedConversationId);
            this.initSocket();
          }
          this.loadingConversations = false;
        })
        .catch((error) => {
          this.loadingConversations = false;
          this.showError(error, () => {
            this.$router.push({
              name: 'social.users',
              params: { query: JSON.stringify({ user: '', categoryId: [] }) }
            });
          });
        });
    }
  }
};
</script>
<style scoped lang="scss">
.conversations-list-panel::before {
  content: none;
}

.conversations-list-header {
  padding: 0;
}

.chat-searchbar ::v-deep .v-text-field__details {
  display: none;
}

.conversations-list-expansion ::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}

.conversation-item {
  border-radius: 16px;
  margin: 6px 0;
  padding: 12px;
  background: #FFFFFF;
  transition: background 0.3s;

  &::before,
  &::after {
    content: none;
  }

  &.v-item--active {
    background: #6B71F2;

    .chat-sidebar-last-message {
      color: #FFFFFFCC !important;
    }

    .chat-name {
      color: #FFFFFF !important;
    }

    .chat-ts {
      color: #FFFFFF99 !important;
    }
  }
}

.chat-sidebar-name {
  display: flex;
  flex-direction: column;
  gap: 3px;
  flex-grow: 1;
  min-width: 0;
}

.chat-name,
.chat-sidebar-name-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
}

.chat-sidebar-name-top .chat-name {
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  min-width: 0;
}

.chat-sidebar-name-top .chat-ts {
  text-wrap: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: #BBBBBB;
}

.chat-sidebar-last-message {
  font-size: 14px;
  font-weight: 400;
  min-width: 0;
}
</style>
