<template>
  <div :class="[{ 'full-width': fullWidth }]">
    <label v-show="customLabel" class="pb-0 mb-0">{{ customLabel }}</label>
    <div>
      <v-select
        v-bind="$attrs"
        :items="items"
        :filled="!!flat"
        :class="[
          'custom-select-field',
          {
            flat,
          },
          cssClasses
        ]"
        @input="updateValue"
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelectField',
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    cssClasses: {
      type: String,
      default: ''
    },
    customLabel: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    updateValue (selection) {
      this.$emit('input', selection);
    }
  }
};
</script>

<style scoped>
.full-width {
  width: 100%;
}

.custom-select-field >>> .v-input__slot {
  border: 1px solid #212833;
  border-radius: 16px;
  min-height: 45px;
  margin-bottom: 0;
  padding-left: 1rem;
}

.custom-select-field >>> .v-input__slot::before,
.custom-select-field >>> .v-input__slot::after {
  content: none;
}

.custom-select-field.flat >>> .v-input__slot {
  border-color: #3F44A60A !important;
  background-color: #F5F7FA !important;
}

.custom-select-field.flat >>> .v-select__selection {
  font-size: 15px;
}

.custom-select-field.flat >>> .v-input__append-inner {
  margin-top: 9px;
}

</style>
