<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showAdd"
    width="500"
    persistent
    @keydown.esc="onClose"
    @keydown.enter="saveCategories"
  >
    <template v-slot:activator="{ on }">
      <v-btn @click="onNewClicked" icon color="title_highlight_color" class="removeHover" v-on="on">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </template>

    <details-card scrollable>
      <v-card-title>
        <prosk-title>
          {{ $t("addCategoryToUser") }}
        </prosk-title>
      </v-card-title>
      <v-card-text>
        <v-col cols="12" class="mt-3">
          <v-autocomplete
            class="rounded-input-autocomplete"
            :items="categories"
            v-model="categoriesSelected"
            color="#212833"
            item-color="#212833"
            item-text="name"
            item-value="id"
            :error-count="errors.length"
            :error-messages="errors"
            chips
            multiple
            solo
            :search-input.sync="searchInput"
            @change="searchInput = ''"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <b>{{ getCategorySubcategoryName(data.item) }}</b>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template #selection="{ item }">
              <v-chip class="main_text_color--text primary_light">
                {{item.name}}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button :action="onClose" outlined :message="$t('cancel')"/>
        <general-button :action="saveCategories" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import _debounce from 'lodash/debounce';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';

export default {
  name: 'AddCategoryToUser',
  components: { DetailsCard, ProskTitle, GeneralButton },
  props: ['showAdd', 'onClose', 'user', 'newItem'],
  mixins: [errorDialog],
  data () {
    return {
      categories: [],
      searchInput: '',
      errors: [],
      isLoading: false,
      categoriesSelected: [],
      loading: false
    };
  },
  created () {
    this.getCategoriesData();
    this.getUserCategories();
  },
  watch: {
    'searchInput': function () {
      this.getCategoriesData(this);
    }
  },
  methods: {
    getCategorySubcategoryName (item) {
      let finalName = '';
      finalName = item.category_name + '-' + item.name;
      return finalName;
    },
    getUserCategories () {
      this.user.categories.forEach((category) => {
        this.categoriesSelected.push(category);
      });
    },
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    onNewClicked () {
      this.categoriesSelected = [];
      this.getUserCategories();
      this.newItem();
    },
    getCategoriesData: _debounce(function () {
        this.isLoading = true;
        const url = (process.env.VUE_APP_BASE_URL + `/api/categories?filters=parent_id=notNull&search=${this.searchInput === null
            ? '' : this.searchInput}&per_page=all`).trim();
        axios.get(url).then((response) => {
          this.categories = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.showError(error);
          this.isLoading = false;
        });
    }, 500),
    saveCategories: function () {
      this.errors = [];
      this.loading = true;
      if (this.categoriesSelected.length === 0) {
        this.errors.push(this.$t('fieldCategoryRequired'));
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.user.id}/categories`;
        const data = {
          user_id: this.user.id,
          category_ids: this.categoriesSelected
        };
        axios
          .patch(url, data)
          .then(() => {
            this.updateUser(this.user.id);
            this.updatePercentage(this.user.id);
            this.onClose();
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
    }
  }
};
</script>
<style scoped>
  .rounded-input-autocomplete >>> .v-input__slot {
    border: solid 1px #bbb;
  }
</style>
