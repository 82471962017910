<template>
  <!-- <component :is="svgPath" /> -->
  <!-- <img :src="svgSrc" :alt="icon" /> -->
  <div class="prosk-icon" v-html="svgHtml"></div>
</template>

<script>
// @TODO: see a way to import all the icons from the svg folder

  import camera from './svg/camera.svg?raw';
  import compartir from './svg/compartir.svg';
  import direcciones from './svg/direcciones.svg';
  import edit from './svg/edit.svg';
  import editBlack from './svg/edit-black.svg';
  import enviarMensaje from './svg/enviar-mensaje.svg';
  import hora from './svg/hora.svg';
  import mail from './svg/mail.svg';
  import mailFilled from './svg/mail-filled.svg';
  import trabajos from './svg/trabajos.svg';
  import user from './svg/user.svg';
  import whatsapp from './svg/whatsapp.svg';

  const icons = {
    camera,
    compartir,
    direcciones,
    edit,
    'edit-black': editBlack,
    'enviar-mensaje': enviarMensaje,
    hora,
    mail,
    'mail-filled': mailFilled,
    trabajos,
    user,
    whatsapp
  };

  export default {
    props: {
      icon: {
        type: String,
        required: true
      }
    },
    computed: {
      svgHtml () {
        return require(`!raw-loader!./svg/${this.icon}.svg`).default;
      },
      svgSrc () {
        return icons[this.icon];
      },
      svgPath () {
        return icons[this.icon]
      }
    }
  }
</script>
<style scoped>
  .prosk-icon {
    display: inline-flex;
    height: 20px;
    vertical-align: middle;
    width: 20px;
  }

  .prosk-icon >>> svg {
    height: auto !important;
    width: 100% !important;
  }
</style>
