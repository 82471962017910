<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showCancellation"
    width="500"
    persistent
    @keydown.esc="onClose"
  >
    <details-card scrollable>
      <v-card-title>
        <prosk-title>
          {{ $t("cancellationReason") }}
        </prosk-title>
      </v-card-title>

      <v-card-text>
        <v-col cols="12">
          <custom-select-field
            :items="cancellationItems"
            :label="$t('selectReasonCancellation')"
            item-text="reason"
            item-value="id"
            :hide-details="reasonCancellationError.length === 0"
            :error-count="reasonCancellationError.length"
            :error-messages="reasonCancellationError"
            v-model="reasonCancellation"
          />
        </v-col>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button :action="onClose" outlined :message="$t('cancel')"/>
        <general-button :action="cancelJob" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import CustomSelectField from '@/components/socialvue/customSelectField/CustomSelectField.vue';

import {
    JOB_STATUS_CLIENT_REJECTED,
    JOB_STATUS_CLIENT_CANCEL,
    JOB_STATUS_PROSKER_CANCEL,
    USER_TYPE_CLIENT
} from '@/misc/constants';

export default {
  name: 'CancellationReasonDialog',
  components: { DetailsCard, ProskTitle, GeneralButton, CustomSelectField },
  props: ['idCancel', 'onClose', 'showCancellation', 'isProsker', 'onAdded'],
  mixins: [errorDialog],
  data () {
    return {
      reasonCancellation: null,
      loading: false,
      cancellationItems: [],
      reasonCancellationError: []
    };
  },
  created () {
      this.getCancellationReasons();
      this.setImportedConstants();
  },
  methods: {
    getCancellationReasons () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/job-cancellation-reasons?filters=type_user=${this.$options.USER_TYPE_CLIENT}`;
        axios.get(url).then((response) => {
            this.cancellationItems = response.data.data
        }).catch((error) => {
            this.showError(error);
        });
    },
    setImportedConstants () {
        this.$options.JOB_STATUS_CLIENT_CANCEL = JOB_STATUS_CLIENT_CANCEL;
        this.$options.JOB_STATUS_CLIENT_REJECTED = JOB_STATUS_CLIENT_REJECTED;
        this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
        this.$options.JOB_STATUS_PROSKER_CANCEL = JOB_STATUS_PROSKER_CANCEL;
    },
    cancelJob () {
      this.reasonCancellationError = [];
      if (this.reasonCancellation) {
        this.loading = true;
        const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${this.idCancel}`;
        const data = {
          status: this.isProsker ? this.$options.JOB_STATUS_PROSKER_CANCEL : this.$options.JOB_STATUS_CLIENT_CANCEL,
          reason: this.reasonCancellation
        };
        axios
          .patch(url, data)
          .then(() => {
            this.onClose();
            this.onAdded();
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      } else {
        this.reasonCancellationError.push(
          this.$t('fieldReasonCancellationRequired')
        );
      }
    }
  }
};
</script>
