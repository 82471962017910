<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showAdd"
    width="500"
    persistent
    scrollable
    @keydown.esc="onClose"
    @keydown.enter="save"
  >
    <template v-slot:activator="{ on }">
      <slot name="customButton" :click="onNewClicked"  :v-on="on" :isEdit="isEdit" ></slot>
    </template>

    <details-card scrollable>
      <v-card-title>
        <prosk-title class="title">
          {{ isEdit ? $t("editUser") : $t("createUser") }}
        </prosk-title>
      </v-card-title>
      <v-card-text>
        <v-row align="start">
          <v-col cols="12" class="">
            <custom-text-field
              :label="$t('firstName')"
              v-model="first_name"
              :hide-details="firstNameErrors.length === 0"
              :error-count="firstNameErrors.length"
              :error-messages="firstNameErrors"
            ></custom-text-field>
          </v-col>
          <v-col cols="12" class="">
            <custom-text-field
              :label="$t('lastName')"
              v-model="last_name"
              :hide-details="lastNameErrors.length === 0"
              :error-count="lastNameErrors.length"
              :error-messages="lastNameErrors"
            ></custom-text-field>
          </v-col>
          <v-col cols="12" class="" v-if="type_user >= $options.USER_TYPE_PROSKER">
            <custom-text-field
              textarea
              :label="$t('description')"
              v-model="description"
              rows="2"
              :hide-details="descriptionErrors.length === 0"
              :error-count="descriptionErrors.length"
              :error-messages="descriptionErrors"
            ></custom-text-field>
          </v-col>
          <v-col cols="12" class="">
            <custom-text-field
              :label="$t('email')"
              v-model="email"
              :disabled="this.isEdit"
              :hide-details="emailErrors.length === 0"
              :error-count="emailErrors.length"
              :error-messages="emailErrors"
            ></custom-text-field>
          </v-col>
          <v-col cols="4" class="">
            <custom-auto-complete
              :items="identification_types"
              :label="$t('identificationType')"
              :hide-details="identification_typeError.length === 0"
              :error-count="identification_typeError.length"
              :error-messages="identification_typeError"
              item-text="value"
              item-value="value"
              v-model="identification_type"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <flag :iso="data.item.country" />
                    <span class="pl-2"> {{ data.item.value }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </custom-auto-complete>
          </v-col>
          <v-col cols="8" class="">
            <custom-text-field
                :label="$t('identificationCard')"
                @keypress="onlyNumber"
                color="#59D79D"
                :hide-details="identification_cardError.length === 0"
                :error-count="identification_cardError.length"
                :error-messages="identification_cardError"
                v-model="identification_card"
            ></custom-text-field>
          </v-col>
          <v-col cols="12" class="">
            <custom-text-field
              :label="$t('userName')"
              v-model="username"
              :hide-details="userNameErrors.length === 0"
              color="#59D79D"
              :error-count="userNameErrors.length"
              :error-messages="userNameErrors"
            ></custom-text-field>
          </v-col>
          <v-col cols="12" class="">
            <custom-auto-complete
              :label="$t('typeUser')"
              :items="usersType"
              v-model="type_user"
              item-text="name"
              disabled
              item-value="user_type"
            ></custom-auto-complete>
          </v-col>
          <v-col cols="12" class="" v-if="type_user >= $options.USER_TYPE_PROSKER">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <custom-text-field
                  v-model="dateFormatted"
                  :label="$t('birthDate')"
                  readonly
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  :click="on.click"
                ></custom-text-field>
              </template>
              <v-date-picker
                v-model="birth_date"
                no-title
                @input="menu = false"
                :locale="($i18n.locale.toLowerCase().includes('br')) ? 'pt' : $i18n.locale"
              ></v-date-picker>
            </v-menu>
            <span v-if="birthDateErrors" class="red--text"> {{ birthDateErrors }} </span>
          </v-col>
          <v-col cols="4">
            <custom-auto-complete
              :items="phoneCodes"
              :label="$t('countryCode')"
              prefix="+"
              :hide-details="phoneCodeError.length === 0"
              :error-count="phoneCodeError.length"
              :error-messages="phoneCodeError"
              item-text="code"
              item-value="code"
              return-object
              v-model="phoneInternationalInfos"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <flag :iso="data.item.country" />
                    <span class="pl-2"> {{ data.item.code }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </custom-auto-complete>
          </v-col>
          <v-col cols="8" class="">
            <custom-text-field
              :label="$t('phoneNumber')"
              @keypress="onlyNumber"
              :counter="phoneInternationalInfos.phoneMask.length"
              :maxlength="phoneInternationalInfos.phoneMask.length"
              :error-count="phoneNumberErrors.length"
              :error-messages="phoneNumberErrors"
              v-model="phone_number"
              v-mask="phoneInternationalInfos.phoneMask"
            ></custom-text-field>
          </v-col>
          <v-col cols="12" class=" relative" v-if="!isEdit">
            <custom-text-field
              :label="$t('password')"
              required
              :hide-details="passwordErrors.length === 0"
              :error-count="passwordErrors.length"
              :error-messages="passwordErrors"
              :customType="showPassword ? 'text' : 'password'"
              v-model="password"
            ></custom-text-field>
            <v-icon v-show="showPassword" @click="onShowPassword" class="absolute">mdi-eye</v-icon>
            <v-icon v-show="!showPassword" @click="onShowPassword" class="absolute">mdi-eye-off</v-icon>
          </v-col>
          <v-col cols="12" class=" relative" v-if="!isEdit">
            <custom-text-field
              :label="$t('confirmPassword')"
              required
              :hide-details="confirmError.length === 0"
              :error-count="confirmError.length"
              :error-messages="confirmError"
              :customType="showConfirmPassword ? 'text' : 'password'"
              v-model="confirmPassword"
            ></custom-text-field>
            <v-icon v-show="showConfirmPassword" @click="onShowConfirmPassword" class="absolute">mdi-eye</v-icon>
            <v-icon v-show="!showConfirmPassword" @click="onShowConfirmPassword" class="absolute">mdi-eye-off</v-icon>
          </v-col>
          <v-col cols="12" class="">
            <custom-text-field
              :label="$t('companyOptional')"
              hide-details
              v-if="type_user >= $options.USER_TYPE_PROSKER"
              v-model="company"
            ></custom-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>

        <general-button :action="onClose" :message="$t('cancel')" outlined/>
        <general-button :action="save" :loading="loading" :message="$t('save')" primary/>
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { USER_TYPE_PROSKER } from '@/misc/constants';
import CustomTextField from '@/components/socialvue/customTextField/CustomTextField.vue';
import CustomAutoComplete from '@/components/socialvue/customAutocomplete/CustomAutoComplete.vue';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import { getIdentificationTypes, typeOnlyNumber, getPhoneCodes, removeMaskFromPhoneNumber } from '@/misc/utils';
import { mask } from 'vue-the-mask'
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneCodes = getPhoneCodes();
const defaultPhoneInternationalInfos = phoneCodes[1];

export default {
  name: 'UserDialog',
  directives: { mask },
  components: { CustomAutoComplete, CustomTextField, DetailsCard, GeneralButton, ProskTitle },
  props: ['showAdd', 'onClose', 'item', 'newItem', 'isEdit'],
  mixins: [errorDialog],
  data () {
    return {
      dateFormatted: null,
      menu: false,
      type_user: null,
      identification_card: null,
      identification_type: null,
      identification_types: getIdentificationTypes(),
      searchPhoneCodesInput: '',
      phoneCodes: phoneCodes,
      phoneInternationalInfos: defaultPhoneInternationalInfos,
      phoneCodeError: [],
      identification_typeError: [],
      identification_cardError: [],
      password: '',
      passwordErrors: [],
      confirmPassword: null,
      confirmError: [],
      usersType: [
        {
          id: 0,
          user_type: 0,
          name: 'Cliente'
        },
        {
          id: 1,
          user_type: 10,
          name: 'Prosker'
        },
        {
          id: 2,
          user_type: 20,
          name: 'Admin'
        }
      ],
      firstNameErrors: [],
      lastNameErrors: [],
      descriptionErrors: [],
      emailErrors: [],
      userNameErrors: [],
      birthDateErrors: null,
      phoneNumberErrors: [],
      username: null,
      searchInput: '',
      loading: false,
      first_name: null,
      birth_date: null,
      company: null,
      last_name: null,
      description: null,
      phone_number: null,
      email: null,
      showPassword: false,
      showConfirmPassword: false,
      banned: 0
    };
  },
  created () {
    this.setImportedConstants();
  },
  watch: {
    birth_date (val) {
        this.dateFormatted = this.formatDate(this.birth_date);
    },
    '$i18n.locale': function (newVal, oldVal) {
        if (newVal) {
            this.dateFormatted = this.formatDate(this.birth_date);
        }
    },
    showAdd: function (value) {
      if (value && this.item && this.isEdit) {
        const originalStartDate = new Date(this.item.birth_date);
        this.birth_date = moment(moment.utc(originalStartDate)).format('YYYY-MM-DD');
        this.username = this.item.username;
        this.first_name = this.item.first_name;
        this.last_name = this.item.last_name;
        this.description = this.item.description;
        this.identification_card = this.item.identification_card;
        this.identification_type = this.item.identification_type;

        if (this.item.phone_code) {
          const foundInternationalInfos = getPhoneCodes().find((phoneCode) => phoneCode.code === parseInt(this.item.phone_code));
          this.phoneInternationalInfos = foundInternationalInfos || defaultPhoneInternationalInfos;
        } else {
          this.phoneInternationalInfos = defaultPhoneInternationalInfos;
        }
        const phoneUtil = PhoneNumberUtil.getInstance();
        if (this.item.phone_number) {
          const formattedPhoneNumber = phoneUtil.formatInOriginalFormat(phoneUtil.parse(this.item.phone_number, this.phoneInternationalInfos.country), this.phoneInternationalInfos.country);
          this.phone_number = formattedPhoneNumber;
        }
        this.type_user = this.item.type_user;
        this.company = this.item.company;
        this.email = this.item.email;
        this.banned = this.item.banned;
      } else {
        // create client by default
        this.type_user = this.usersType[0].user_type;
      }
    }
  },
  computed: {
      computedDateFormatted () {
          return this.formatDate(this.birth_date)
      },
      ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    onlyNumber ($event) {
      return typeOnlyNumber($event);
    },
    formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        if (this.$i18n.locale.toLowerCase().includes('en')) {
            return `${month}/${day}/${year}`
        } else {
            return `${day}/${month}/${year}`
        }
    },
    setImportedConstants () {
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
    },
    onNewClicked () {
      this.resetErrors();
      this.newItem();
    },
    resetErrors () {
      this.firstNameErrors = [];
      this.identification_typeError = [];
      this.lastNameErrors = [];
      this.descriptionErrors = [];
      this.emailErrors = [];
      this.identification_cardError = [];
      this.phoneCodeError = [];
      this.userNameErrors = [];
      this.birthDateErrors = null;
      this.phoneNumberErrors = [];
      this.passwordErrors = [];
      this.confirmError = [];
    },
    reset () {
      this.username = null;
      this.loading = false;
      this.first_name = null;
      this.identification_card = null;
      this.identification_type = null;
      this.phoneInternationalInfos = defaultPhoneInternationalInfos;
      this.birth_date = null;
      this.last_name = null;
      this.phone_number = null;
      this.email = null;
      this.banned = 0;
      this.password = '';
      this.confirmPassword = null;
    },
    ...mapActions({
      updateUser: 'auth/updateUser'
    }),
    validatePhone (phone, phoneInternationalInfos) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      return phoneUtil.isValidNumberForRegion(phoneUtil.parse(removeMaskFromPhoneNumber(phone), phoneInternationalInfos.country), phoneInternationalInfos.country)
    },
    onShowPassword () {
      this.showPassword = !this.showPassword;
    },
    onShowConfirmPassword () {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    save () {
      let error = false;
      this.resetErrors();

      const passwordValidation = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_.;,\\])[A-Za-z\d@$!%*#?&\-_.;,\\]{8,}$/;
      if (!this.password.match(passwordValidation) && !this.isEdit) {
        this.passwordErrors.push(this.$t('invalidPassword'));
        error = true;
      }
      if (this.password !== this.confirmPassword && !this.isEdit) {
        this.confirmError.push(this.$t('passwordsMustMatch'));
        error = true;
      }
      if (!this.identification_card) {
        this.identification_cardError.push(this.$t('fieldIdentificationCardRequired'));
        error = true;
      }
      if (!this.identification_type) {
        this.identification_typeError.push(this.$t('fieldIdentificationTypeRequired'));
        error = true;
      }
      if (!this.first_name) {
        this.firstNameErrors.push(this.$t('fieldNameRequired'));
        error = true;
      }
      if (!this.last_name) {
        this.lastNameErrors.push(this.$t('fieldLastNameRequired'));
        error = true;
      }
      if (!this.email) {
        this.emailErrors.push(this.$t('fieldEmailRequired'));
        error = true;
      }
      if (!this.username) {
        this.userNameErrors.push(this.$t('fieldUserRequired'));
        error = true;
      }
      if (!this.birth_date && this.type_user === this.$options.USER_TYPE_PROSKER) {
        this.birthDateErrors = this.$t('fieldBirthDateRequired');
        error = true;
      }
      if (!this.phoneInternationalInfos.code) {
        this.phoneCodeError.push(this.$t('fieldCountryCodeRequired'));
        error = true;
      }
      if (!this.phone_number) {
        this.phoneNumberErrors.push(this.$t('fieldPhoneNumberRequired'));
        error = true;
      }
      if (!this.validatePhone(this.phone_number, this.phoneInternationalInfos)) {
        this.phoneNumberErrors.push(this.$t('enterValidPhone'));
        error = true;
      }

      if (!error) {
        this.loading = true;
        const originalDate = new Date(this.birth_date);
        const formattedDate = moment(moment.utc(originalDate)).format(
          'DD-MM-YYYY'
        );

        const data = {
          first_name: this.first_name,
          last_name: this.last_name,
          description: this.description,
          email: this.email,
          username: this.username,
          identification_type: this.identification_type,
          identification_card: this.identification_card,
          birth_date: formattedDate,
          phone_number: removeMaskFromPhoneNumber(this.phone_number),
          phone_code: this.phoneInternationalInfos.code
        };

        if (!this.isEdit) {
          data.banned = this.banned;
          const clientTypeNumber = this.usersType[0].user_type;
          data.type_user = clientTypeNumber;
          data.birth_date = null;
          data.password = this.password;
          data.password_confirmation = this.confirmPassword;
          data.banner_img = null;
          data.profile_img = null;
        }

        if (!this.isEdit) {
          const url = `${process.env.VUE_APP_BASE_URL}/api/users`;
          axios
            .post(url, data)
            .then(() => {
              this.loading = false;
              this.onClose();
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error, false, true);
            });
        } else {
          const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.item.id}`;
          axios
            .put(url, data)
            .then((response) => {
              this.updateUser(response.data.data.id);
              this.loading = false;
              this.onClose();
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error, false, true);
            });
        }
      }
    }
  }
};
</script>
<style scoped>
  h3.title {
    margin: 0;
    padding: 0 9px;
  }

  .relative {
    position: relative;
    width: 100%;
  }

  .absolute {
    position: absolute;
    top: 20px;
    right: 12px;
  }
</style>
