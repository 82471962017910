<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="show"
    width="300"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="button" icon v-on="on">
        <prosk-icon icon="compartir" />
      </v-btn>
    </template>

    <details-card scrollable>
      <v-card-title>
        <prosk-title>
          {{$t('share')}}
          <v-icon  class="white--text ">mdi-share-variant-outline</v-icon>
        </prosk-title>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-col cols="12">
            <social-media-button :onClick="shareOnFacebook" socialIcon="facebook" size="block" />
            <social-media-button :onClick="shareOnTwitter" socialIcon="twitter" size="block" cssClasses="mt-2"/>
          </v-col>
        </v-container>
      </v-card-text>
    </details-card>
  </v-dialog>
</template>
<script>
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SocialMediaButton from '@/components/socialvue/buttons/SocialMediaButton.vue';
import ProskIcon from '@/icons/ProskIcon.vue';
import DetailsCard from '../cards/DetailsCard.vue';
import ProskTitle from '../markup/ProskTitle.vue';

export default {
  name: 'ShareDialog',
  components: { ProskIcon, SocialMediaButton, DetailsCard, ProskTitle },
  props: ['show', 'networks', 'sharing', 'buttonSize'],
  mixins: [errorDialog],
  data () {
    return {
      loading: false,
      skill_name: null,
      skills: [],
      nameError: []
    };
  },
  methods: {
    shareOnFacebook () {
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.sharing)}`;
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    },
    shareOnTwitter () {
      const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.sharing)}`;
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    }
  }
};
</script>
<style scoped>
.button {
  background-color: #3F44A612;
  height: 40px;
  padding: 9px;
  width: 40px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>
