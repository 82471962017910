<template>
  <div>
    <v-row>
      <v-col class="review-avatar">
        <v-avatar width="4rem" height="4rem">
          <img
            v-if="!user.profile_img"
            src="@/assets/images/page-img/avatar.png"
            alt="user"
            class="avatar-70"
          />
          <img
            v-else
            :src="user.profile_img"
            class="rounded-circle avatar-70"
            alt="user"
          />
        </v-avatar>
      </v-col>
      <v-col class="review-header">
        <h4 class="name secondary--text">
          {{ user.first_name }}
          {{ user.last_name }}
        </h4>
        <div class="d-flex">
          <rating v-model="rating" class="test" readonly />
          <p class="hint_text_color--text date">
            {{ getDateFormat(created_at) }}
            {{
              getDateFormat(created_at) > 1
                ? $t("daysAgo")
                : $t("dayAgo")
            }}
        </p>
        </div>
      </v-col>
    </v-row>
    <h5 class="review-title dark_blue--text">{{ title }}</h5>
    <p class="review-content hint_text_color--text">
      {{ content }}
    </p>
  </div>
</template>

<script>
import Rating from '@/components/socialvue/profile/rating.vue';
import moment from 'moment';

export default {
  name: 'UserReviewsItem',
  components: { Rating },
  props: ['user', 'title', 'content', 'rating', 'created_at'],
  methods: {
    getDateFormat (date) {
      const serverDate = new Date(date);
      const myDate = new Date();
      const difference = myDate - serverDate;
      return moment(moment.utc(difference)).format('D');
    }
  }
};
</script>
<style scoped>
.review-avatar {
  flex-basis: 4rem;
  flex-grow: 0;
}

.review-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.review-header .date {
  margin-left: 0.5rem;
}

h4.name {
  font-size: 18px;
  font-weight: 600;
}

h5.review-title {
  font-size: 16px;
  font-weight: 700;
  margin-top: 24px;
}

.review-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}
</style>
