<template>
  <general-button
    :action="onClick"
    :small="!size || size === 'small'"
    :large="size === 'large'"
    :x-large="size === 'x-large'"
    :loading="loading"
    :cssClasses="[cssClasses, {block: size === 'block'}]"
  >
    <div class="d-flex align-center">
      <v-icon v-if="socialIcon==='facebook'" color="#3F44A6">mdi-facebook</v-icon>
      <span v-if="socialIcon==='facebook'"> Facebook</span>

      <v-icon v-if="socialIcon==='twitter'" color="#1DA1F2">mdi-twitter</v-icon>
      <span v-if="socialIcon==='twitter'"> Twitter</span>
    </div>
  </general-button>
</template>
<script>
import GeneralButton from './GeneralButton.vue';

export default {
  name: 'SocialMediaButton',
  props: ['onClick', 'loading', 'socialIcon', 'size', 'color', 'cssClasses'],
  components: {
    GeneralButton
  }
};
</script>
<style scoped>
.block {
  width: 100%;
}
</style>
