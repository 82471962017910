<template>
  <v-responsive class="overflow-y-hidden fill-height" height="600" max-height="100%">
    <details-card class="conversation" card-class="d-flex flex-column fill-height">
      <v-system-bar class="conversation-header" lights-out height="98px">
        <v-avatar width="50px" height="50px" class="mr-4">
          <img :src="recipientUser.profile_img" class="rounded-circle" :style="conversation.online ? 'border: 2px solid #53FAA5' : ''" alt="user" />
        </v-avatar>
        <span class="text-md black--text">{{ conversation.title }}</span>
        <v-spacer></v-spacer>
        <jobs-dialog
          :item="editedItem"
          :on-close="onAddClose"
          :new-item="newItem"
          :show-add="showAdd"
          :on-added="onAddJobCallback"
          :selected-user="selectedUser"
          :show-alert="true"
          :show-jobs="showJobs"
          v-if="$options.JOBS_ENABLED && currentUser.type_user >= $options.USER_TYPE_PROSKER"
        />
      </v-system-bar>

      <!-- loading view -->
      <v-card-text class="flex-grow-1 overflow-y-auto" v-if="loadingMessages">
        <v-row>
          <v-col sm="6">
            <v-skeleton-loader
              v-for="index in 5"
              :key="index"
              v-bind="skeletonAttrs"
              type="list-item"
            ></v-skeleton-loader>
          </v-col>
          <v-col sm="3">
          </v-col>
          <v-col sm="3">
            <v-skeleton-loader
              v-for="index in 3"
              :key="index"
              v-bind="skeletonAttrs"
              type="list-item"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- real view -->
      <v-card-text ref="conversation" class="scroller flex-grow-1 overflow-y-auto" v-if="!loadingMessages">
        <div class="text-center mb-4" v-if="!hideLoadMoreButton">
          <v-btn
            x-small
            plain
            link
            color="teal darken-4"
            @click="loadMoreMessages()"
          >
            <v-icon>mdi-cached</v-icon>
            <span class="ml-2">{{ $t("loadOldMessages") }}</span>
          </v-btn>
        </div>
        <div v-for="(msg, i) in messages" :key="i" :class="{ 'd-flex flex-row-reverse': (msg.from_user_id === currentUser.id) }">
          <v-menu offset-y max-width="180">
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <div
                  :class="(msg.from_user_id === currentUser.id) ? 'right light_grey dark_blue--text' : 'left title_highlight_color white--text'"
                  class="message-block"
                  v-on="on"
                >
                  <div>
                    <span v-if="(!msg.deleted_at)">{{ msg.message }}</span>
                    <span class="font-italic text--grey" v-if="msg.deleted_at">
                      <v-icon small>mdi-minus-circle</v-icon>
                      {{ $t('messageDeleted') }}
                    </span>
                  </div>

                  <div class="message-info">
                    <small>
                      {{ getMessageDate(msg.created_at) }}
                    </small>
                    <small>
                      {{ getMessageTime(msg.created_at) }}
                    </small>
                    <v-icon v-if="(msg.from_user_id === currentUser.id) && hover && !msg.deleted_at" small>mdi-chevron-down</v-icon>
                  </div>
                </div>
              </v-hover>
            </template>
            <v-list class="pa-0">
              <v-list-item>
                <v-btn
                x-small
                plain
                link
                color="text--black text-center"
                >
                  <v-icon small class="pr-2">mdi-message-text</v-icon>
                  {{ getMessageDate(msg.created_at) }} {{ getMessageTime(msg.created_at) }}
                </v-btn>
              </v-list-item>
              <v-list-item v-if="msg.id && (msg.from_user_id === currentUser.id)">
                <v-btn
                x-small
                plain
                link
                @click="deleteMessage(msg.id)"
                >
                  <v-icon small class="pr-2">mdi-delete</v-icon>
                  {{ $t('delete') }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="anchor"></div>
      </v-card-text>

      <v-card-text class="conversation-send flex-shrink-1 d-flex flex-row">
        <custom-text-field
          class="flex-grow-1"
          v-model="currentMessageText"
          :placeholder="`${$t('typeMessage')}...`"
          type="text"
          no-details
          outlined
          @keyup.enter="sendMessage"
          @click:append-outer="sendMessage"
          hide-details
        />
        <!-- append-outer-icon="mdi-send" -->
        <general-button :action="sendMessage" size="medium" class="title_text_color" elevation="0">
          <prosk-icon icon="enviar-mensaje" />
        </general-button>
      </v-card-text>
    </details-card>
  </v-responsive>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
import CustomTextField from '@/components/socialvue/customTextField/CustomTextField.vue';
import JobsDialog from '@/components/socialvue/dialogs/JobsDialog.vue';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { USER_TYPE_PROSKER, FEATURE_JOBS, MERCADOPAGO_ACCOUNT } from '@/misc/constants';
import { isFeatureEnabled } from '@/misc/featureFlagService';
import GeneralButton from '../../../components/socialvue/buttons/GeneralButton.vue';
import ProskIcon from '../../../icons/ProskIcon.vue';
import userDataMixin from '../../../mixins/userDataMixin';

export default {
  props: ['conversation', 'message'],
  components: { CustomTextField, DetailsCard, GeneralButton, JobsDialog, ProskIcon },
  name: 'Conversation',
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      messages: [],
      hideLoadMoreButton: true,
      showJobs: true,
      loadingMessages: true,
      skeletonAttrs: {
        class: 'mb-6',
        elevation: 0
      },
      recipientUser: null,
      currentMessageText: '',
      page: 1,
      editedItem: {},
      showAdd: false,
      selectedUser: null,
      errorSendingMessage: ''
    };
  },
  created () {
    this.fillMissingData();
    this.setImportedConstants();
    this.setUsers();
    this.getMessages();
  },
  watch: {
    conversation: function (newVal, oldVal) {
      this.setUsers();
      this.resetMessages();
      this.getMessages();
    },
    message: function (newVal, oldVal) {
      this.messages.push(newVal.message);
      setTimeout(() => {
        this.$refs.conversation.scrollTop = this.$refs.conversation.scrollHeight
      }, 200);
    },
    getMissingData: function (value) {
        if (value) {
            this.fillMissingData();
        }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
    getMissingData () {
      return this.$store.getters['auth/missingData'];
    }
  },
  methods: {
    sendDataLayerSendMessage () {
      this.trackUserData('sent_message', {
        prosker_name: this.conversation.title
      })
    },
    fillMissingData () {
      this.missingData = [];
      this.showJobs = !this.getMissingData.includes(this.$options.MERCADOPAGO_ACCOUNT);
    },
    onAddClose () {
      this.editedItem = {};
      this.showAdd = false;
      this.editedItem.isEdit = false;
      this.selectedUser = null;
    },
    reset () {
        this.editedItem = {};
    },
    newItem () {
        this.showAdd = true;
        this.conversation.users.filter((user) => user.id !== this.currentUser.id).forEach((user) => {
            this.selectedUser = user.id;
        });
    },
    onAddJobCallback () {
        this.reset();
    },
    setImportedConstants () {
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.JOBS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_JOBS);
      this.$options.MERCADOPAGO_ACCOUNT = MERCADOPAGO_ACCOUNT;
    },
    setUsers () {
      if (this.conversation) {
        this.recipientUser = this.conversation.users.find(user => user.id !== this.currentUser.id);
        if (!this.recipientUser.profile_img) {
          this.recipientUser.profile_img = require('@/assets/images/page-img/avatar.png');
        }
      }
    },
    resetMessages () {
      this.page = 1;
      this.messages = [];
    },
    onAddCallback () {
      this.$router.push({ name: 'social.services', params: { query: 'mis-servicios' } });
      this.reset();
    },
    getMessageTime (messageDate) {
      const originalDate = new Date(messageDate);
      const hour = moment(moment.utc(originalDate)).add('hours').format('HH:mm');
      return hour;
    },
    getMessageDate (messageDate) {
      const originalDate = new Date(messageDate);
      return moment(moment.utc(originalDate)).format('DD/MM/YYYY');
    },
    sendMessage () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/conversations/${this.conversation.id}/messages`;
      const message = {
        message: this.currentMessageText,
        created_at: new Date(),
        from_user_id: this.currentUser.id
      };
      this.$emit('new-message', { content: message, userId: this.conversation.recipientUserId });
      this.messages.push(message);
      // TODO - find a better way to scroll to bottom
      setTimeout(() => {
        this.$refs.conversation.scrollTop = this.$refs.conversation.scrollHeight
      }, 200);
      this.currentMessageText = '';
      axios
        .post(url, message)
        .then(() => {
          this.sendDataLayerSendMessage();
        })
        .catch((error) => {
          this.sendDataLayerSendMessage();
          this.errorSendingMessage = '1';
          if (error.message) {
            this.errorSendingMessage = error.message;
          }
          this.currentMessageText = message.message;
          this.messages.pop();
        });
    },
    loadMoreMessages () {
      this.page++;
      this.getMessages();
    },
    getMessages () {
      this.loadingMessages = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/messages?page=${this.page}&filters=conversation_id=${this.conversation.id},deleted=1&sortBy=created_at,desc`;
      axios
        .get(url)
        .then((response) => {
          this.messages = this.messages
            .concat(response.data.data)
            .sort((a, b) => {
             if (a.created_at <= b.created_at) {
              return -1;
             } else {
              return 1;
             }
            });
          this.loadingMessages = false;
          this.hideLoadMoreButton = response.data.data.length === 0 || (this.page === response.data.last_page);
          setTimeout(() => {
            this.$refs.conversation.scrollTop = this.$refs.conversation.scrollHeight
          }, 200);
        })
        .catch((error) => {
          this.showError(error);
          this.loadingMessages = false;
        });
    },
    deleteMessage (messageId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/conversations/${this.conversation.id}/messages/${messageId}`;
      axios
        .delete(url)
        .then(() => {
          this.messages.find((item) => item.id === messageId).deleted_at = new Date();
        })
        .catch((error) => {
          this.showError(error);
        });
    }
  }
};
</script>
<style scoped>
  .conversation {
    height: 100%;
  }

  .conversation-header {
    border-bottom: 1px solid #E8E8E8;
    flex-shrink: 0;
    padding: 0 32px;
  }

  .message-block {
    padding: 24px;
    border-radius: 24px 24px 24px 0;
    width: 50%;
    margin: 6px 0;
  }

  .message-block.right {
    border-radius: 24px 24px 0 24px;
  }

  .message-info {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    gap: 16px;
    font-size: 10px;
  }

  .conversation-send {
    align-items: center;
    gap: 8px;
  }
</style>
